import React, { useEffect, useState } from 'react'
import { compileOverviewTopics } from '../../mqtt/Functions'
import { getSingletonPubSub } from '../../mqtt/MyPubSub'

const PullupCard = ({ valueObj, index, selectedRecord }) => {
  const [cardValues, setCardValues] = useState(valueObj.values)
  const topicBase = `device/${selectedRecord.type}/${selectedRecord.thingName}/telemetry`

  // MQTT Subscriptions
  useEffect(() => {
    let subscriptions = []; // Ensure subscriptions is initialized

    const getLiveValues = async () => {
      try {
        const pubsub = await getSingletonPubSub();

        subscriptions = cardValues.map((value, index) =>
          pubsub.subscribe({
            topics: [`${topicBase}/${value.valueKey}`],
          }).subscribe({
            next: (data) => {
              setCardValues((prevData) => {
                return prevData.map((item, i) =>
                  i === index ? { ...item, value: data.value } : item
                );
              });
            },
            error: (error) => console.error("Subscription error:", error),
            complete: () => console.log("Subscription completed"),
          })
        );
      } catch (error) {
        console.error("Failed to subscribe:", error);
      }
    };

    getLiveValues();

    return () => {
      // Unsubscribe properly when component unmounts
      subscriptions.forEach((sub) => sub.unsubscribe());
    };
  }, [])

  return (
    <div key={index} className='data-display-card-collapsed icc-card' >
      <div className='data-display-start'>
        <h4 style={{ fontSize: '24px' }}>{valueObj.title}</h4>
        <div />
      </div>
      <div className='data-display-end'>
        {cardValues.map((value, subIndex) => {
          return (<div key={subIndex} className='row' style={{ marginTop: '9px' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p>{value.name}</p>
            </div>
            <p>{typeof value.value === 'number' ? value.value.toFixed(2) : value.value}{value.unit}</p>
          </div>)
        })}
      </div>
    </div>
  )
}

export default PullupCard
