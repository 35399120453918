import React, { useState } from 'react'
import './styles/StatisticsContainer.css'
import StatisticsGraphContainer from '../GraphComponent/StatisticsGraphContainer.jsx'
import { statDatasets, unitValues, statTopics } from '../../constants/statVars';
import StatCard from './components/StatCard';

export default function StatisticsContainer({
  selectedRecord,
  toggleAlert,
  headerValue,
}) {
  const [numGraphsDisplayed, setnumGraphsDisplayed] = useState(1);
  const deviceId = selectedRecord?._id

  const filterCardValues = (arr, numGraphsDisplayed) => {
    if (!Array.isArray(arr)) return arr;
    return numGraphsDisplayed === 1 ? arr.slice(0, 4) : [...arr.slice(0, 3), arr[4]];
  };

  const filteredCardValues = filterCardValues(statTopics, numGraphsDisplayed);

  return (
    <div className='statistics-body-container' numgraphsdisplayed={numGraphsDisplayed}>
      <div className='statistics-start-container'>
        {filteredCardValues.map((valueObj, index) => {
          return (
            <StatCard key={crypto.randomUUID()} valueObj={valueObj} selectedRecord={selectedRecord} numGraphsDisplayed={numGraphsDisplayed} />
          )
        })}
      </div>
      <div className='statistics-end-container'>

        {/* Graph + Controls */}
        <StatisticsGraphContainer
          key={crypto.randomUUID()}
          chartIndex={0}
          deviceId={deviceId}
          headerValue={headerValue}
          numGraphsDisplayed={numGraphsDisplayed}
          setnumGraphsDisplayed={setnumGraphsDisplayed}
          toggleAlert={toggleAlert}
        />

        {numGraphsDisplayed === 2 &&
          <StatisticsGraphContainer
            chartIndex={1}
            key={crypto.randomUUID()}
            deviceId={deviceId}
            numGraphsDisplayed={numGraphsDisplayed}
            headerValue={headerValue}
            setnumGraphsDisplayed={setnumGraphsDisplayed}
            toggleAlert={toggleAlert}
          />
        }
      </div>
    </div>
  )
}
