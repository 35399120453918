import React, { useEffect, useState } from 'react'
import { getSingletonPubSub } from '../../../mqtt/MyPubSub';
import { unitValues } from '../../../constants/statVars.js';

const StatCard = ({ valueObj, index, selectedRecord, numGraphsDisplayed }) => {
  const [cardValues, setCardValues] = useState(valueObj.values)
  const topicBase = `device/${selectedRecord.type}/${selectedRecord.thingName}/statistics`

  useEffect(() => {
    let subscriptions;
    const getLiveValues = async () => {
      try {
        const pubsub = await getSingletonPubSub();
        subscriptions = cardValues.map((value, index) =>
          pubsub.subscribe({
            topics: [`${topicBase}/${value.valueKey}`],
          }).subscribe({
            next: (data) => {
              setCardValues((prevData) => {
                return prevData.map((item, i) =>
                  i === index ? { ...item, value: data.value } : item
                );
              });
            },
            error: (error) => console.error("Subscription error:", error),
            complete: () => console.log("Subscription completed"),
          })
        );
      } catch (error) {
        console.error("Failed to subscribe:", error);
      }
    };

    getLiveValues();

    return () => {
      // Unsubscribe properly when component unmounts
      if (subscriptions) {
        subscriptions.forEach((sub) => sub.unsubscribe());
      }
    };
  }, [numGraphsDisplayed])


  return (
    <>
      <div className="stat-card small-card" key={index}>
        <h4>{valueObj.title}</h4>
        {cardValues.map((data, idx) => (
          <div key={idx} className="row">
            <p>{data.name}</p>
            {data?.value === 'N/A'
              ? <p>{data?.value}</p>
              : <p>{data?.value + unitValues[data.unit].unit}</p>
            }
          </div>
        ))}
      </div>
    </>
  );
}

export default StatCard
