import { getSingletonPubSub } from "./MyPubSub";

export function compileOverviewTopics(data) {
  let topicsOut = [];
  let channelNum = 0;

  switch (data.type) {
    case "ccm":
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ctlTemp")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ctlRel")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/conStat")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/instPwr")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/pwrFact")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambTemp")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambHum")
      break;
    case "hcc":
      for (let index = 0; index < 8; index++) { //check channels on device data and refine loop// TODO

        topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/temp" + channelNum)
        topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/relStat" + channelNum)
      }
      break;
    case "casem":
      //TODO
      break;
    default:
      break;
  }
  return topicsOut
}

export function getLiveDataCardTopics(data) {
  let topicsOut = []
  switch (data.type) {
    case 'ccm':
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ctlTemp")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ctlRel")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/conStat")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/ctlSp")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/ctlDiff")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/defTime")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/defInt")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/defTemp")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/instPwr")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/pwrFact")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambTemp")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambHum")
      break
    case 'hcc':
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/scrnStat")
      break
    case 'casem':
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambTemp")
      topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambHum")
      break
    default:
      break;

  }

}

// export function GraphTopics(data) {
//   let topicsOut = []
//   switch (data.type) {
//     case "ccm":
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ctlTemp")
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ctlRel")
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/conStat")
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/ctlSp")
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/ctlDiff")
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/defTime")
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/defInt")
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/settings/defTemp")
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/instPwr")
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/pwrFact")
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambTemp")
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambHum")
//       break;
//     case "hcc":
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/scrnStat")
//       break;
//     case "casem":
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambTemp")
//       topicsOut.push("device/" + data.type + "/" + data.thingName + "/telemetry/ambHum")
//       break;
//     default:
//       break;
//   }
// }

// Publish New Control Settings
export async function pubsubPublishSettings(type, id, payload) { //Publish settings
  try {
    let singletonPubSub = await getSingletonPubSub()
    await singletonPubSub.publish({ topics: `device/${type}/${id}/cmd`, message: payload });
    const message = 'Settings updated successfully'
  } catch (error) {
    const message = 'Error updating settings'
    console.error('Error publishing message:', error);
  }
}
