import { createSlice, configureStore } from "@reduxjs/toolkit";
const statistics = createSlice({
  name: "statistics",
  initialState: {
    selectedUnitId: {
      0: null,
      1: null,
    },
    datasets: {
      0: [],
      1: []
    },
    chart: {
      granularity: 'HOURLY',
      startDate: null,
      endDate: null,
    },
    fetchedData: {},
    sync: false,
    syncZoom: null, // Add syncZoom state
    syncCursor: null, // Add syncCursor state
    xScale: null,
  },
  reducers: {
    setSelectedUnitId: (state, action) => {
      state.selectedUnitId = action.payload;
    },
    setDatasets: (state, action) => {
      state.datasets = action.payload;
    },
    setChart: (state, action) => {
      state.chart = action.payload;
    },
    setFetchedData: (state, action) => {
      state.fetchedData = action.payload;
    },
    setSync: (state, action) => {
      state.sync = action.payload;
    },
    setSyncZoom: (state, action) => {
      state.syncZoom = action.payload;
    },
    setSyncCursor: (state, action) => {
      state.syncCursor = action.payload;
    },
    setXScale: (state, action) => {
      state.xScale = action.payload;
    }
  }
});

export const {
  setSelectedUnitId,
  setDatasets,
  setChart,
  setFetchedData,
  setSync,
  setSyncZoom,
  setSyncCursor, // Export setSyncCursor action
  setXScale
} = statistics.actions;

export const statisticsStore = configureStore({
  reducer: statistics.reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  })
});
