import React, { useEffect, useRef, useState } from "react";
import './styles/Graph.css'
import { getSingletonPubSub } from "../../mqtt/MyPubSub";
import * as d3 from "d3";

const GraphContainer = ({
  selectedRecord,
  relayDisplayData,
  tempDisplayData,
  powerDisplayData,
  powerFactorDisplayData,
  displayDate,
}) => {
  const containerRef = useRef();
  const svgRef = useRef();
  const [zoomScale, setZoomScale] = useState()
  const [dimensions, setDimensions] = useState({ width: 800, height: 400 });
  const [powerValue, setPowerValue] = useState(0)
  const [powerFactorValue, setPowerFactorValue] = useState(0)
  const [tempValue, setTempValue] = useState(0)
  const [liveTempDisplayData, setLiveTempDisplayData] = useState(tempDisplayData);
  const [liveRelayDisplayData, setLiveRelayDisplayData] = useState(relayDisplayData);
  const [livePowerDisplayData, setLivePowerDisplayData] = useState(powerDisplayData);
  const [livePowerFactorDisplayData, setLivePowerFactorDisplayData] = useState(powerFactorDisplayData);
  const topicKeys = ['ctlTmp', 'ctlRel', 'pwrFact', 'instPwr']
  const topicBase = `device/${selectedRecord.type}/${selectedRecord.thingName}/telemetry`
  const types = {
    ctlTmp: 'ccm_control_temp',
    ctlRel: 'ccm_control_relay',
    pwrFact: 'ccm_pwr_fact',
    instPwr: 'ccm_inst_pwr',
  }

  function convertResponse(response) {

    return {
      deviceID: selectedRecord.thingName,
      // timestamp: new Date(Date.now()).getTime(),
      timestamp: new Date(response.dateCaptured).getTime(),
      metadata: {},
      type: types[response.measureName],
      [response.measureName]: response.value
    };

  }

  const liveDataMerge = (topic, message) => {
    const parsedMessage = convertResponse(message)

    switch (topic) {
      case 'ctlTmp':

        setLiveTempDisplayData((prevData) => {
          const updatedData = [...prevData, parsedMessage];
          return updatedData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        });
        break;
      case 'ctlRel':
        setLiveRelayDisplayData((prevData) => {
          const updatedData = [...prevData, parsedMessage];
          return updatedData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        });
        break;
      case 'pwrFact':
        setLivePowerFactorDisplayData((prevData) => {
          const updatedData = [...prevData, parsedMessage];
          return updatedData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        });
        break;
      case 'instPwr':
        setLivePowerDisplayData((prevData) => {
          const updatedData = [...prevData, parsedMessage];
          return updatedData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        });
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    let subscriptions;
    const getLiveValues = async () => {
      try {
        const pubsub = await getSingletonPubSub();
        subscriptions = topicKeys.map((value, index) =>
          pubsub.subscribe({
            topics: [`${topicBase}/${value}`],
          }).subscribe({
            next: (data) => {
              const dateCaptured = new Date(data.dateCaptured);
              if (isSameDay(dateCaptured, new Date(displayDate))) {
                const topic = data.measureName;
                liveDataMerge(topic, data);
              }
            },
            error: (error) => console.error("Subscription error:", error),
            complete: () => console.log("Subscription completed"),
          })
        );
      } catch (error) {
        console.error("Failed to subscribe:", error);
      }
    };

    getLiveValues();

    return () => {
      // Unsubscribe properly when component unmounts
      if (subscriptions) {
        subscriptions.forEach((sub) => sub.unsubscribe());
      }
    };
  }, [])

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  // Generate Relay Graph Data
  const generateRelayGraphData = () => {
    // console.log(liveRelayDisplayData)
    const segments = [];
    let currentSegment = null;

    liveRelayDisplayData.forEach((d) => {
      if (d.ctlRel === 1) {
        if (!currentSegment) {
          // Start a new segment
          currentSegment = { start: d?.timestamp };
        }
      } else {
        if (currentSegment) {
          // End the current segment
          currentSegment.end = d?.timestamp;
          segments.push(currentSegment);
          currentSegment = null;
        }
      }
    });

    // If the last segment is still open, close it
    if (currentSegment) {
      currentSegment.end = [liveRelayDisplayData.length - 1]?.timestamp;
      segments.push(currentSegment);
    }

    return segments;
  }

  const createTimeScale = (date, width) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0); // Set time to midnight

    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999); // Set time to end of the day

    return d3.scaleTime()
      .domain([startOfDay, endOfDay])
      .range([0, width]); // Adjust to your SVG width
  }

  // Draw the Relay Graph
  function drawRelayGraph(group, segments, xScale, yValue) {
    const path = d3.path();

    segments.forEach(segment => {
      const xStart = xScale(new Date(segment.start));
      const xEnd = xScale(new Date(segment.end));

      if (isNaN(xStart) || isNaN(xEnd)) {
        // console.warn("Invalid xScale mapping:", segment);
        return;
      }

      path.moveTo(xStart, yValue);
      path.lineTo(xEnd, yValue);
    });

    group.append("path")
      .attr("d", path.toString())
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("clip-path", "url(#clip-graph)")
      .attr("stroke-width", 6)
  }

  const getClosestPoint = (data, timeValue) => {
    data.sort((a, b) => new Date(a?.timestamp) - new Date(b?.timestamp));

    // Define the bisector for the? timestamp
    const bisectTimestamp = d3.bisector(d => new Date(d?.timestamp).getTime()).center;

    // Find the index of the closest point
    const index = bisectTimestamp(data, timeValue.getTime());

    // Handle edge cases and find the closest point
    let closestData;
    if (index === 0) {
      closestData = data[0]; // First point
    } else if (index >= data.length) {
      closestData = data[data.length - 1]; // Last point
    } else {

      // Compare the two closest points
      const prev = data[index - 1];
      const curr = data[index];
      closestData = Math.abs(new Date(curr?.timestamp).getTime() - timeValue.getTime()) <
        Math.abs(new Date(prev?.timestamp).getTime() - timeValue.getTime())
        ? curr
        : prev;
    }

    return closestData

  }

  // Responsive Graph
  // Window observer, checks if window is getting resized
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (entries.length === 0) return;
      const { width, height } = entries[0].contentRect;
      setDimensions(prev =>
        prev.width !== width || prev.height !== height
          ? { width, height }
          : prev
      );
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const { width, height } = dimensions;
    const margin = { top: 0, right: 50, bottom: 90, left: 75 };
    const totalGraphHeight = height - margin.top - margin.bottom;
    const relayGraphHeight = 30; // Fixed height for the relay graph
    const remainingHeight = totalGraphHeight - relayGraphHeight;
    const graphHeight = remainingHeight / 2
    const graphWidth = (width - margin.right - margin.left);

    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    svg.selectAll("*").remove(); // remove previous content

    const g = svg.append("g").attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Scales for the different axis and graphs 
    let xScale = createTimeScale(displayDate, graphWidth)
    if (zoomScale) {
      xScale = zoomScale.transform.rescaleX(xScale)
    }

    const tempExtent = d3.extent(liveTempDisplayData, (d) => d?.ctlTmp)
    const tempMargin = (tempExtent[1] - tempExtent[0]) * 0.3;
    const tempScale = d3
      .scalePow()
      .domain([tempExtent[0] - tempMargin, tempExtent[1] + tempMargin])
      .range([graphHeight, 0]);


    const powerExtent = d3.extent(livePowerDisplayData, (d) => d?.instPwr)
    const powerMargin = (powerExtent[1] - powerExtent[0]) * 0.3;
    const powerScale = d3
      .scalePow()
      .domain([powerExtent[0] - powerMargin, powerExtent[1] + powerMargin])
      .range([relayGraphHeight + graphHeight * 2, relayGraphHeight + graphHeight]);


    const powerFactorScale = d3
      .scalePow()
      .domain([0, 1])
      .range([(relayGraphHeight + graphHeight * 2), relayGraphHeight + graphHeight]);

    // Setup the d3 zoom behavior
    const zoom = d3.zoom()
      .scaleExtent([1, 10]) // Zoom scalee range (min: 1, max: 10)
      .translateExtent([[0, 0], [width, height]])
      .on("zoom", zoomed); // Handle zoom events

    svg.call(zoom); // Apply zoom behavior to the SVG

    // Gridlines
    const drawGridlines = (scale, orientation, count, transform, lineOrientation, isVertical = false) => {
      const axis = orientation(scale)
        .tickValues(count)
        .tickSize(isVertical ? -(graphHeight * 2 + relayGraphHeight) : -graphWidth) // Dynamically set tick size
        .tickFormat("");

      const gridGroup = g.append("g")
        .attr("transform", transform)
        .call(axis).selectAll(".tick line").attr("stroke", "lightgray").attr('class', `${lineOrientation}`);
    };

    let currentXScale = xScale

    const domain = powerScale.domain();
    const powerTicks = d3.range(domain[0], domain[1], (domain[1] - domain[0]) / 10);
    powerTicks.push(domain[1]);

    // Ticks
    let timeTicks = currentXScale.ticks(11);
    let tempTicks = tempScale.ticks(11);
    let powerFactorTicks = powerFactorScale.ticks(11);

    // Draw the gridlines
    drawGridlines(currentXScale, d3.axisBottom, timeTicks, `translate(0,${relayGraphHeight + graphHeight * 2})`, 'vertical', true); // X-axis gridlines
    drawGridlines(powerScale, d3.axisRight, powerTicks, `translate(${graphWidth}, 0)`, 'horizontal');
    drawGridlines(tempScale, d3.axisRight, tempTicks, `translate(${graphWidth}, 0)`, 'horizontal');


    // Zoom function
    function zoomed(event) {
      const transform = event.transform;
      setZoomScale(event);

      // Rescale the X-axis based on zoom
      currentXScale = transform.rescaleX(xScale); // Rescale X-axis

      // Update the X-axis with the new scale
      g.select(".x-axis").call(d3.axisBottom(currentXScale));

      g.selectAll('.vertical').remove();
      drawGridlines(currentXScale, d3.axisBottom, currentXScale.ticks(11), `translate(0,${relayGraphHeight + graphHeight * 2})`, 'vertical', true); // X-axis gridlines

      // Update the lines based on the new X-scale
      g.select(".temp-line")
        .attr("d", tempLine.x(d => currentXScale(new Date(d?.timestamp))).y(d => tempScale(d.ctlTmp)));
      g.select(".power-line")
        .attr("d", powerLine.x(d => currentXScale(new Date(d?.timestamp))).y(d => powerScale(d?.instPwr)));
      g.select(".powerFactor-line")
        .attr("d", powerFactorLine.x(d => currentXScale(new Date(d?.timestamp))).y(d => powerFactorScale(d?.pwrFact)));

      // Redraw the relay graph
      relayGroup.selectAll("path").remove();
      drawRelayGraph(relayGroup, relaySegments, currentXScale, relayGraphYValue);

      svg.selectAll('.closest-dot').remove();
    }

    // Axes
    const xAxis = d3.axisBottom(currentXScale).tickValues(timeTicks);
    const tempYAxis = d3.axisLeft(tempScale).tickValues(tempTicks)
    const powerYAxis = d3.axisLeft(powerScale).tickValues(powerTicks);
    const powerFactorYAxis = d3.axisRight(powerFactorScale).tickValues(powerFactorTicks);

    g.append("g")
      .attr("transform", `translate(0,${relayGraphHeight + graphHeight * 2})`)
      .attr('class', 'x-axis')
      .call(xAxis)
      .selectAll("text")
      .style("font-size", "14px");

    g.append("g")
      .attr("transform", `translate(0,0)`)
      .call(tempYAxis)
      .selectAll("text")
      .style("font-size", "14px");

    g.append("g")
      .attr("transform", `translate(0,0)`)
      .call(powerYAxis)
      .selectAll("text")
      .style("font-size", "14px");

    g.append("g")
      .attr("transform", `translate(${graphWidth},0)`)
      .call(powerFactorYAxis)
      .selectAll("text")
      .style("font-size", "14px");

    // Line Generators
    const tempLine = d3
      .line()
      .x((d) => currentXScale(new Date(d?.timestamp)))
      .y((d) => tempScale(d?.ctlTmp));

    const powerLine = d3
      .line()
      .x((d) => currentXScale(new Date(d?.timestamp)))
      .y((d) => powerScale(d?.instPwr));

    const powerFactorLine = d3
      .line()
      .x((d) => currentXScale(new Date(d?.timestamp)))
      .y((d) => powerFactorScale(d?.pwrFact));

    // Create a clip path to restrict the lines to the graph area
    const clipPath = svg.append("defs").append("clipPath").attr("id", "clip-graph");
    clipPath.append("rect")
      .attr("x", 1)
      .attr("y", 0)
      .attr("width", graphWidth - 1)
      .attr("height", relayGraphHeight + graphHeight * 2); // Clip to the height of the graph area

    // Draw lines
    const relayGroup = svg.append("g")
      .attr("transform", `translate(${margin.left}, ${relayGraphHeight + margin.top})`)
      .attr("class", 'relay-graph')

    const relaySegments = generateRelayGraphData()
    const relayGraphYValue = graphHeight - relayGraphHeight / 2

    drawRelayGraph(relayGroup, relaySegments, xScale, relayGraphYValue)

    g.append("path")
      .datum(liveTempDisplayData)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 3)
      .attr("class", "temp-line")
      .attr("clip-path", "url(#clip-graph)")
      .attr("d", tempLine);

    g.append("path")
      .datum(livePowerFactorDisplayData)
      .attr("fill", "none")
      .attr("stroke", "green")
      .attr("stroke-width", 3)
      .style("stroke-dasharray", "4 4") // Dashed line for Power Factor
      .attr("class", "powerFactor-line")
      .attr("clip-path", "url(#clip-graph)")
      .attr("d", powerFactorLine);

    g.append("path")
      .datum(livePowerDisplayData)
      .attr("fill", "none")
      .attr("stroke", "orange")
      .attr("stroke-width", 3)
      .attr("class", "power-line")
      .attr("clip-path", "url(#clip-graph)")
      .attr("d", powerLine);

    // Cursor
    const cursorLine = g.append("line")
      .attr("class", "cursor-line")
      .attr("x1", 0)
      .attr("x2", 0)
      .attr("y1", 0)
      .attr("y2", graphHeight * 2 + relayGraphHeight)
      .attr("stroke", "darkred")
      .attr("stroke-width", 3)
      .style("stroke-dasharray", "4 4") // Dashed line for Power Factor
      .style("display", "none"); // Initially hidden

    // Cursor Label 
    const cursorLabelGroup = svg.append("g")
      .style("display", "none");

    cursorLabelGroup.append("rect")
      .attr("width", 60) // Adjust width as needed
      .attr("height", 20) // Adjust height as needed
      .attr("fill", "white")
      .attr("stroke", "black")
      .attr("rx", 5)
      .attr("ry", 5);

    const cursorLabelText = cursorLabelGroup.append("text")
      .attr("x", 30) // Center text within the rectangle
      .attr("y", 12) // Adjust to vertically center text
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .style("font-size", "12px");

    // Labels (outside the graph)
    svg
      .append("text")
      .attr("x", margin.left / 2)
      .attr("y", margin.top + graphHeight / 2)
      .attr("transform", `rotate(-90, ${margin.left / 2}, ${margin.top + graphHeight / 2})`)
      .attr("text-anchor", "middle")
      .text("Temperature");

    svg
      .append("text")
      .attr("x", margin.left / 2)
      .attr("y", margin.top + graphHeight + graphHeight / 2)
      .attr("transform", `rotate(-90, ${margin.left / 2}, ${margin.top + graphHeight + graphHeight / 2})`)
      .attr("text-anchor", "middle")
      .text("Power");


    // Handle scroll zooming (zoom in or out with scroll)
    svg.on("wheel", function (event) {
      event.preventDefault();
      const zoomDelta = event.deltaY > 0 ? 1.1 : 0.9;
      const currentTransform = d3.zoomTransform(this);
      const newTransform = currentTransform.scale(zoomDelta);
      svg.transition().duration(150).call(zoom.transform, newTransform); // Apply the zoom transformation
    });

    svg.on('mousemove', function (event) {
      const [mouseX] = d3.pointer(event, svg.node())
      const adjustedX = mouseX - margin.left
      const timeValue = currentXScale.invert(adjustedX);
      const formattedTime = d3.timeFormat("%H:%M:%S")(timeValue);

      // Get the corresponding closest point and its y-values
      const closestPowerPoint = getClosestPoint(livePowerDisplayData, timeValue);
      const closestPowerFactorPoint = getClosestPoint(livePowerFactorDisplayData, timeValue);
      const closestTempPoint = getClosestPoint(liveTempDisplayData, timeValue);

      const closestPoints = [];

      if (closestPowerPoint) {
        const powerY = powerScale(closestPowerPoint.instPwr);
        setPowerValue(parseFloat(closestPowerPoint.instPwr.toFixed(2)));

        closestPoints.push({
          x: closestPowerPoint.timestamp, y: powerY, color: 'orange'
        })
      }
      if (closestPowerFactorPoint) {
        const powerFactorY = powerFactorScale(closestPowerFactorPoint.pwrFact);
        setPowerFactorValue(parseFloat(closestPowerFactorPoint.pwrFact.toFixed(2)));

        closestPoints.push({
          x: closestPowerFactorPoint.timestamp, y: powerFactorY, color: 'green'
        })
      }
      if (closestTempPoint) {
        const tempY = tempScale(closestTempPoint.ctlTmp);
        setTempValue(parseFloat(closestTempPoint.ctlTmp.toFixed(2)));

        closestPoints.push({
          x: closestTempPoint.timestamp, y: tempY, color: 'steelblue'
        })
      }

      // Line
      cursorLine
        .attr('x1', adjustedX)
        .attr('x2', adjustedX)
        .attr("clip-path", "url(#clip-graph)")
        .style("display", 'block')

      // Label
      cursorLabelText.text(formattedTime);
      cursorLabelGroup
        .attr("transform", `translate(${mouseX - 30}, ${relayGraphHeight + graphHeight * 2})`) // Position at bottom of graph
        .style("display", "block");

      // Remove old dots
      svg.selectAll('.closest-dot').remove();

      // Create new dots
      svg.selectAll('.closest-dot')
        .data(closestPoints)
        .enter()
        .append('circle')
        .attr('class', 'closest-dot')
        .attr("clip-path", "url(#clip-graph)")
        .attr('r', 5)
        .attr('fill', d => d.color)
        .attr('cx', d => {
          if (d.x || d.y) {
            return currentXScale(new Date(d.x)) + margin.left
          }
        })
        .attr('cy', d => d.y)
        .style('display', 'block');

    }).on('mouseleave', function () {
      cursorLine.style('display', 'none');
      cursorLabelGroup.style("display", "none");
      svg.selectAll('.closest-dot').remove();

      setPowerValue(0);
      setPowerFactorValue(0);
      setTempValue(0);
    });

    svg.on('')

  }, [livePowerDisplayData, liveTempDisplayData, livePowerFactorDisplayData, liveRelayDisplayData, dimensions]);

  return (
    <div ref={containerRef} style={{ width: "100%", height: "calc(100% - 100px )" }} >
      <div className="legends">
        <div className="legend" target='power'>
          <div className="circle" />
          <p>{powerValue}W</p>
        </div>
        <div className="legend" target='temp'>
          <div className="circle" />
          <p>{tempValue}°C</p>
        </div>
        <div className="legend" target='factor'>
          <div className="circle" />
          <p>{powerFactorValue}</p>
        </div>
      </div>
      <svg ref={svgRef}></svg>
    </div >
  );
};

export default GraphContainer
