import React, { useState, useEffect } from 'react'
import './styles/LogsContainer.css'
import { getDeviceLogs } from '../../api/DeviceAPI'
import { SyncLoader } from 'react-spinners';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SensorsIcon from '@mui/icons-material/Sensors';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
export default function LogsContainer({ selectedRecord, serialNumber, mqttMessagesArray }) {

  const deviceId = selectedRecord._id
  const deviceThingName = selectedRecord.thingName
  const [dataLoading, setdataLoading] = useState(false);
  const [logData, setlogData] = useState([]);
  const [latestLiveLogIndex, setlatestLiveLogIndex] = useState(0);

  const logStatusIcons = [
    <InfoOutlinedIcon sx={{ fontSize: '18px', color: '#0b51b9' }} />,
    <SensorsIcon sx={{ fontSize: '18px', color: 'green' }} />,
    <SensorsOffIcon sx={{ fontSize: '18px', color: 'gray' }} />
  ]

  useEffect(() => { //init
    requestData()
  }, [selectedRecord]);

  // useEffect(() => {
  //   if (mqttMessagesArray) {
  //     compileLiveLogs(mqttMessagesArray)
  //   }
  // }, [mqttMessagesArray]);

  // useEffect(() => {
  //   if (latestLiveLogIndex !== 0) {
  //     setTimeout(() => {
  //       setlatestLiveLogIndex(0)
  //     }, 3000);
  //   }
  // }, [latestLiveLogIndex]);


  function compileLiveLogs(data) {
    data.forEach(logObj => {
      if (logObj.deviceID === deviceThingName) {
        let dateValue = null
        if ('dateCaptured' in logObj) {
          dateValue = logObj.dateCaptured
        } else {
          dateValue = logObj?.timestamp
        }
        const dateA = new Date(dateValue)
        const dateB = new Date(logData[0]?.timestamp)
        if (dateA > dateB) {
          const compiledObj = { ...logObj, timestamp: dateValue, metadata: { topic: logObj.topic }, expanded: false }
          setlatestLiveLogIndex(prev => prev + 1)
          setlogData(prev => [compiledObj, ...prev])
        } else {
          // console.log("nope: "+dateA)
        }
      }
    });
  }

  async function requestData() { //get persisted log data from backend
    if (deviceId) {
      setdataLoading(true)
      const logDataIn = await getDeviceLogs(deviceId, 250)
      const dataItems = logDataIn
      if (dataItems) {
        const expandedStates = []
        dataItems.forEach(item => {
          expandedStates.push("none")
        });
        setlogData(dataItems)
      }
      setdataLoading(false)
    }
  }

  function togglelogsExpandedState(index) {//open close animation
    let copyLogData = JSON.parse(JSON.stringify(logData))
    if (copyLogData[index]?.expanded === true) {
      copyLogData[index].expanded = false
    } else {
      copyLogData[index].expanded = true
    }
    setlogData(copyLogData)

  }


  function compileLogDataToJSON(dataIn) {
    let dataOut = []
    try {
      const parsedDataIn = JSON.parse(dataIn)
      Object.keys(parsedDataIn).forEach(key => {
        if (typeof parsedDataIn[key] === "object") {
          dataOut.push({ level: 0, key, value: '' })
          Object.keys(parsedDataIn[key]).forEach(subKey => {
            dataOut.push({ level: 1, key: subKey, value: parsedDataIn[key][subKey] })
          })
        } else {
          dataOut.push({ level: 0, key, value: parsedDataIn[key] })
        }
      });
    } catch (error) {
      dataOut = []
    }
    return dataOut
  }

  return (
    <div className='logs-container' style={{
      height: '90%',
      width: '100%'
    }}>
      <h2 style={{ marginTop: '12px', marginBottom: 0 }}>{serialNumber}</h2>
      {dataLoading ?
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '30px' }}>
          <SyncLoader
            size={12}
            color={'#042855'}
          />
        </div>
        :
        <>
          {logData.length > 0 ?
            (logData.map((log, index) => {
              let parsedData = []
              let maxHeight = 25 + 9 //for topic text
              let createdDate = log.timestamp
              let iconIndex = 0
              if (log?.timestamp) {
                const formattedDate = new Date(log.timestamp)
                createdDate = formattedDate.toLocaleDateString() + " (" + formattedDate.toLocaleTimeString() + ")"
              }
              if (log?.log) {
                if (log?.log === "connected") {
                  iconIndex = 1
                } else if (log?.log === "disconnected") {
                  iconIndex = 2
                } else {
                  parsedData = compileLogDataToJSON(log.log)
                  maxHeight = maxHeight + (21 * parsedData.length)
                }
              }
              const logIsExpanded = log.expanded

              return (
                <div key={index} className={'log-card-container' + ((index < latestLiveLogIndex) ? ' color-fade' : "")} >
                  <div style={{
                    borderBottom: logIsExpanded ? '1px solid gray' : '0px',
                    paddingBottom: logIsExpanded ? '3px' : '0px'
                  }} className='log-card-toggle-container' onClick={() => togglelogsExpandedState(index)} >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      {/* <InfoOutlinedIcon sx={{ fontSize: '18px', color: '#042855' }} /> */}
                      {logStatusIcons[iconIndex]}
                      <p style={{ fontWeight: 500, margin: 0, marginLeft: '3px' }}>
                        {createdDate}</p>
                    </div>
                    {logIsExpanded ?
                      <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </div>
                  <div className={'log-card-body-container log-' + (logIsExpanded ? "open" : 'close')} style={{ "--my-css-var": `${maxHeight}px` }}>
                    <p style={{ marginTop: '9px' }} className='topic-text'>
                      <span style={{ fontWeight: 600, marginRight: '1px' }}>{"Topic: "}</span>
                      {log?.metadata?.topic}</p>
                    {parsedData.map((dataPoint, subIndex) => {
                      return (
                        <p className='log-text' style={{ fontWeight: 300, marginLeft: dataPoint.level === 0 ? "6px" : '24px' }} key={subIndex}>
                          <span style={{ fontWeight: 500, marginRight: '1px' }}>{dataPoint.key + ": "}</span>
                          {dataPoint.value}</p>)
                    })}
                  </div>
                </div>
              )
            })) : <p>No logs available...</p>
          }
        </>}
    </div>
  )
}
