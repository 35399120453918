import AcUnitIcon from '@mui/icons-material/AcUnit';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
import SimCardIcon from '@mui/icons-material/SimCard';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import DataObjectIcon from '@mui/icons-material/DataObject';

export const sortData = [
  // COLD CASES , HOT CASES, CASEM
  {
    title: ['Device Name', 'Serial Number', 'Firmware Version', 'Customer'],
    sortKeys: ['name', 'serialNumber', 'firmwareVersion', 'customer.name']
  },
  // SIM FLEET
  {
    title: ['ICC ID', 'Serial Number', 'Profile', 'State', 'Data'],
    sortKeys: ['iccid', 'serialNumber', 'serviceProfile', 'state', 'dataUsage']
  },
  // CUSTOMERS
  {
    title: ['Name', 'Address', 'Email'],
    sortKeys: ['name', 'address', 'email']
  },
  // FIRMWARE
  {
    title: ['Type', 'Version Name', 'Description', 'Date Created'],
    sortKeys: ['type', 'versionName', 'description', 'createdAt']
  }

]

export const simStateName = {
  A: "ActiveLive",
  B: "InactiveNew",
  C: "InactiveSleep",
  D: "InactiveStopped",
  I: "MigratedFrom",
  N: "NotProvisioned",
  O: "MigratedTo",
  R: "ActiveReady",
  S: "ActiveSuspend",
  T: "ActiveTest",
  U: "ActiveStandby",
  V: "InactiveTransformed",
  W: "SuspendRegulatory",
  X: "Inactive",
  Z: "Terminated"
}

export const menuItems = [
  {
    index: 0,
    title: "Cold Cases",
    pullupEnabled: true,
    search: true,
    sort: true,
    order: true,
    filter: true,
    showAddButton: false,
    showEditButton: true,
    showSettingButton: true,
    sortValue: sortData[0],
    icon: <AcUnitIcon className='menu-icon' />
  },
  {
    index: 1,
    title: "Hot Cases",
    pullupEnabled: true,
    search: true,
    sort: true,
    order: true,
    filter: true,
    showAddButton: false,
    showEditButton: true,
    showSettingButton: true,
    sortValue: sortData[0],
    icon: <WhatshotIcon className='menu-icon' />
  },
  {
    index: 2,
    title: "CASEM",
    pullupEnabled: true,
    search: true,
    sort: true,
    order: true,
    filter: true,
    showAddButton: false,
    showEditButton: true,
    showSettingButton: true,
    sortValue: sortData[0],
    icon: <FormatColorResetIcon className='menu-icon' />
  },
  {
    index: 3,
    title: "SIM Fleet ",
    pullupEnabled: false,
    search: true,
    sort: true,
    order: true,
    filter: false,
    showAddButton: false,
    showEditButton: false,
    showSettingButton: false,
    sortValue: sortData[1],
    icon: <SimCardIcon className='menu-icon' />
  },
  {
    index: 4,
    title: "Customers",
    pullupEnabled: false,
    search: true,
    sort: true,
    order: true,
    filter: false,
    showAddButton: true,
    showEditButton: true,
    showSettingButton: false,
    sortValue: sortData[2],
    icon: <PersonPinIcon className='menu-icon' />
  },
  {
    index: 5,
    title: "Firmware",
    pullupEnabled: false,
    search: true,
    sort: true,
    order: true,
    filter: false,
    showAddButton: true,
    showEditButton: true,
    showSettingButton: false,
    sortValue: sortData[3],
    icon: <DataObjectIcon className='menu-icon' />
  }]


export const columnData = {
  0: [//Cold Cases
    { widthIndex: 2, header: "Status", columnKey: 'status' },
    { widthIndex: 2, header: "ID", columnKey: 'thingName' },
    { widthIndex: 8, header: "Serial Number", columnKey: 'serialNumber' },
    { widthIndex: 8, header: "Device Name", columnKey: 'name' },
    { widthIndex: 8, header: "Firmware Version", columnKey: 'firmwareVersion' },
    { widthIndex: 8, header: "Customer", columnKey: 'customerName' },
  ],
  1: [//Hot Cases
    { widthIndex: 2, header: "Status", columnKey: 'status' },
    { widthIndex: 2, header: "ID", columnKey: 'thingName' },
    { widthIndex: 8, header: "Serial Number", columnKey: 'serialNumber' },
    { widthIndex: 8, header: "Device Name", columnKey: 'name' },
    { widthIndex: 8, header: "Firmware Version", columnKey: 'firmwareVersion' },
    { widthIndex: 8, header: "Customer", columnKey: 'customerName' },
  ],
  2: [//Cold Cases
    { widthIndex: 2, header: "Status", columnKey: 'status' },
    { widthIndex: 2, header: "ID", columnKey: 'thingName' },
    { widthIndex: 8, header: "Serial Number", columnKey: 'serialNumber' },
    { widthIndex: 8, header: "Device Name", columnKey: 'name' },
    { widthIndex: 8, header: "Firmware Version", columnKey: 'firmwareVersion' },
    { widthIndex: 8, header: "Customer", columnKey: 'customerName' },
  ],
  3: [//SIM Fleet
    { widthIndex: 8, header: "ICC ID", columnKey: 'iccid' },
    { widthIndex: 5, header: "Serial Number", columnKey: 'serialNumber' },
    { widthIndex: 3, header: "Profile", columnKey: 'serviceProfile' },
    { widthIndex: 4, header: "State", columnKey: 'state', enums: simStateName },
    { widthIndex: 4, header: "Data", columnKey: 'dataRemaining', suffix: 'MB' }
  ],
  4: [//Customers
    { widthIndex: 3, header: "Name", columnKey: 'name' },
    { widthIndex: 3, header: "Address", columnKey: 'address' },
    { widthIndex: 3, header: "Email", columnKey: 'email' },
  ],
  5: [//Firmware
    { widthIndex: 3, header: "Type", columnKey: 'type' },
    { widthIndex: 3, header: "Version Name", columnKey: 'versionName' },
    { widthIndex: 3, header: "Description", columnKey: 'description' },
    { widthIndex: 3, header: "Date Created", columnKey: 'createdAt' },
  ]
}

export const columnSearchableKeys = {
  0: ["name", "serialNumber", ["customer", "name"]],
  1: ["name", "serialNumber", ["customer", "name"]],
  2: ["name", "serialNumber", ["customer", "name"]],
  3: ["imsi", "serialNumber"],
  4: ["name", 'address', 'email'],
}

export const iccOverviewData = {
  'collapsed': [
    {
      title: "State", 
      values: [
        { name: 'Control Temp', value: 'N/A', unit: '\u00b0C', valueKey: 'ctlTmp' },
        { name: 'Relay State', value: 'N/A', unit: '', valueKey: 'ctlRel', values: ['Off', 'On'] },
        { name: 'State', value: 'N/A', unit: '', valueKey: 'conStat', values: ['Off', 'On'] }]
    },
    {
      title: "Energy", values: [
        { name: 'Instantaneous Power', value: 'N/A', unit: 'W', valueKey: 'instPwr' },
        { name: 'Power Factor', value: 'N/A', unit: '', valueKey: 'pwrFact' },
        { name: 'Month to Date', value: 'N/A', unit: '', valueKey: '' }]
    },
    {
      title: "Ambient", values: [
        { name: 'Ambient Temp', value: 'N/A', unit: '\u00b0C', valueKey: 'ambTemp' },
        { name: 'Ambient Humidity', value: 'N/A', unit: '%', valueKey: 'ambHum' }]
    },
  ],
  'expanded': [
    {
      title: "System State", 
      type: 'telemetry',
      values: [
        { name: 'Control Temp', value: 'N/A', colorIndex: 1, unit: '\u00b0C', valueKey: 'ctlTmp' },
        { name: 'Relay State', value: 'N/A', colorIndex: 4, unit: '', valueKey: 'ctlRel' },
        { name: 'State', value: 'N/A', unit: '', valueKey: 'conStat', values: ['Off', 'On'] }]
    },
    {
      title: "Control Settings", 
      type: 'settings',
      values: [
        { name: 'Setpoint', value: 'N/A', unit: '', valueKey: 'ctlSp' },
        { name: 'Differential', value: 'N/A', unit: '', valueKey: 'ctlDiff' },
        { name: 'Defrost Time', value: 'N/A', unit: 'min', valueKey: 'defTime' },
        { name: 'Defrost Interval', value: 'N/A', unit: '', valueKey: 'defInt' },
        { name: 'Defrost Temp', value: 'N/A', unit: '\u00b0C', valueKey: 'defTemp' }]
    },
    {
      title: "Energy Usage", 
      type: 'telemetry',
      values: [
        { name: 'Instant Power', value: 'N/A', colorIndex: 2, unit: 'W', valueKey: 'instPwr' },
        { name: 'Power Factor', value: 'N/A', colorIndex: 3, unit: '', valueKey: 'pwrFact' },
        { name: 'Month to Date', value: 'N/A', unit: '', valueKey: '' }]
    },
  ]

}
