import React, { useEffect, useState, useCallback } from 'react'
import './styles/StatisticsGraphContainer.css'
import StatisticsGraph from './StatisticsGraph.jsx'
import AddchartIcon from '@mui/icons-material/Addchart';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import CloseIcon from '@mui/icons-material/Close';
import Dataset from '../Dataset.jsx';
import { FormControl, Select, MenuItem, OutlinedInput } from '@mui/material';
import { useSelector } from 'react-redux';
import { setDatasets, setChart, statisticsStore, setSync } from '../../stores/statisticsStore'
import { getRandomColorHex } from '../../utils/getRandomColorHex';
import DatePicker from 'react-datepicker';

const StatisticsGraphContainer = ({ headerValue, deviceId, numGraphsDisplayed, setnumGraphsDisplayed, chartIndex }) => {
  const maxDatasets = 8; // Max Number Of Datasets In The Dataset Menu
  const chart = useSelector(state => state.chart)
  const datasets = useSelector(state => state.datasets)
  const fetchedData = useSelector(state => state.fetchedData)
  const sync = useSelector(state => state.sync);

  const handleSyncLockClick = () => {
    statisticsStore.dispatch(setSync(!sync));
  }

  // function to add dataset components
  const addDataset = () => {
    if (datasets[chartIndex].length < maxDatasets) {
      let newDatasets = { ...datasets }

      newDatasets[chartIndex] = [
        ...datasets[chartIndex], {
          id: crypto.randomUUID(),
          hoverValue: null,
          graphType: null,
          type: null,
          color: getRandomColorHex(),
        }]

      statisticsStore.dispatch(setDatasets(newDatasets));

    } else {
      alert('Maximum Number Of Datasets (4)')
    }
  }


  const handleChange = (changeValue, value) => {
    let newChart = {
      ...chart,
    }

    newChart[changeValue] = value
    statisticsStore.dispatch(setChart(newChart));
  }

  return (
    <div className='statistics-graph-container' >
      <div className='dataset-menu'>
        <div className='menu-title'>
          <h2>{headerValue} Datasets </h2>
        </div>
        {datasets[chartIndex].map((dataset, index) => {
          return <Dataset key={dataset?.id} datasetId={dataset?.id} deviceId={deviceId} data={dataset} chartIndex={chartIndex} datasetIndex={index} />
        })}
        <button
          className='add-dataset'
          target={datasets[chartIndex].length < maxDatasets ? 'enabled' : 'disabled'}
          onClick={() => addDataset()}
        >
          + Add New
        </button>
      </div>
      <div className='graph-container'>
        <div className='toolbar'>
          <div className='left'>
            <FormControl sx={{ width: 150, height: 30 }}>
              <Select
                fullWidth
                displayEmpty
                value={chart.granularity}
                onChange={(e) => { handleChange('granularity', e.target.value) }}
                input={<OutlinedInput />}
                sx={{ height: 30 }}

                // MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem key='hourly' value={'HOURLY'}>
                  <em>Hourly</em>
                </MenuItem>
                <MenuItem key='daily' value={'DAILY'}>
                  <em>Daily</em>
                </MenuItem>
              </Select>
            </FormControl>

            <DatePicker
              dateFormat={"yyyy/MM/dd"}
              selected={chart.granularity === "HOURLY" ? chart.startDate : null}
              startDate={chart.granularity === "DAILY" ? chart.startDate : null}
              endDate={chart.granularity === "DAILY" ? chart.endDate : null}
              selectsRange={chart.granularity === "DAILY"}
              onChange={(dates) => {
                let newChart = { ...chart };
                if (chart.granularity === "DAILY") {
                  const [start, end] = dates;
                  newChart.startDate = start;
                  newChart.endDate = end;
                } else {
                  newChart.startDate = dates;
                }
                statisticsStore.dispatch(setChart(newChart));
              }}
              className="input-field statistics-datepicker"
              id={chart.granularity === "HOURLY" ? 'hourly-date-picker' : 'daily-date-picker'}
            />
          </div>
          <div className='right'>
            <SyncLockIcon
              onClick={() => handleSyncLockClick()}
              sx={{ color: sync ? 'blue' : 'inherit', cursor: 'pointer' }}
            />
            {numGraphsDisplayed === 1 && chartIndex === 0
              ? <AddchartIcon onClick={() => setnumGraphsDisplayed(2)} />
              : <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setnumGraphsDisplayed(1)} />
            }
          </div>
        </div>
        <div style={{ width: '100%', height: '100%' }}>
          <StatisticsGraph
            datasets={datasets}
            sync={sync}
            fetchedData={fetchedData}
            numGraphsDisplayed={numGraphsDisplayed}
            chartIndex={chartIndex}
          />
        </div>
      </div>
    </div>
  )
}

export default StatisticsGraphContainer
