import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import DataTable from '../components/Dashboard/DataTable'
import SideNav from '../components/Dashboard/SideNav'
import TopToolbar from '../components/Dashboard/TopToolbar'
import PullupContainer from '../components/PullupContainer'
import { menuItems } from '../constants/dataVars'
import './styles/Dashboard.css'
import AddEditDevice from '../modals/AddEditDevice'
import AddEditCustomer from '../modals/AddEditCustomer'
import CustomAlert from '../modals/CustomAlert'
import AddEditSim from '../modals/AddEditSim'
import { getAllDevices, getDeviceChannelsById, getDeviceTelemetryById } from '../api/DeviceAPI'
import { getAllCustomers } from '../api/CustomerAPI'
import protectedRoute from './ProtectedRoute'
import { fetchAuthSession } from 'aws-amplify/auth';
import { convertDateToIncludeOffset } from '../components/GraphComponent/Functions'
import EditChannelModal from '../modals/EditChannelModal'
import ControlSettings from '../components/ControlSettings.js'
import { getAllFirmware } from '../api/FirmwareAPI.js'
import AddEditFirmware from '../modals/AddEditFirmware.js'
import { getAllSims } from '../api/SimsAPI'


const Dashboard = ({ handleSignOut }) => {
  const [showControlSettings, setShowControlSettings] = useState(false)
  const [selectedSettingsRecord, setSelectedSettingsRecord] = useState('')

  //Display Table Data
  const [ccmDevices, setccmDevices] = useState([]);
  const [hccDevices, sethccDevices] = useState([]);
  const [casemDevices, setcasemDevices] = useState([]);
  const [customers, setcustomers] = useState([]);
  const [firmware, setFirmware] = useState([]);
  const [sims, setSims] = useState([])
  const tableDataArray = [ccmDevices, hccDevices, casemDevices, sims, customers, firmware]

  //Selected Data
  const [selectedRecord, setselectedRecord] = useState({});
  const [selectedMenuIndex, setselectedMenuIndex] = useState(0); //current menu option slected (left sidenav)
  const [deviceChannelData, setdeviceChannelData] = useState([]);
  const [deviceChannelDataLive, setdeviceChannelDataLive] = useState({}); //for live CCM overview data

  //Search / sort / order / filter
  const [searchTerm, setsearchTerm] = useState('');
  const [sortBy, setsortBy] = useState('none')
  const [orderBy, setorderBy] = useState('asc')
  const [filters, setfilters] = useState({ 0: true, 1: true, 2: true });

  //Loaders
  const [tableLoading, settableLoading] = useState(false);
  const [deviceChannelDataLoading, setdeviceChannelDataLoading] = useState(false);
  const [getCustomersLoading, setgetCustomersLoading] = useState(false);
  const [getFirmwareLoading, setgetFirmwareLoading] = useState(false);

  //Modal display states + vars
  const [showEditChannelModal, setShowEditChannelModal] = useState(false)
  const [showDeviceModal, setshowDeviceModal] = useState(false);
  const [showCustomerModal, setshowCustomerModal] = useState(false);
  const [showFirmwareModal, setshowFirmwareModal] = useState(false);
  const [showConfirmModal, setshowConfirmModal] = useState(false);
  const [showSimsModal, setShowSimsModal] = useState(false)
  const [confirmProps, setconfirmProps] = useState({ type: 0, message: "not specified" })

  //Edit data vars
  const [modalInEditMode, setmodalInEditMode] = useState(false);
  const [editRecordData, seteditRecordData] = useState();
  const [editChannel, setEditChannel] = useState({ channelID: '', channelName: '' })

  //Router navigation
  const navigate = useNavigate()
  const [liveDeviceProps, setliveDeviceProps] = useState({});

  //Alert props
  const [showCustomAlert, setshowCustomAlert] = useState(false)
  const [alertProps, setalertProps] = useState({ alertType: 0, message: "not specified" })
  const [mqttMessagesArray, setmqttMessagesArray] = useState([]);
  const [profileName, setprofileName] = useState('Account');

  useEffect(() => {
    const loadTable = true
    getUserDetails()
    getDataFromDatabase(0, loadTable)
    getDataFromDatabase(4, false) //get customers
  }, []);

  async function getUserDetails() {
    try {
      const current = await fetchAuthSession();
      const { given_name, family_name } = current?.tokens?.idToken?.payload
      const fullname = given_name + " " + family_name
      setprofileName(fullname)
    } catch (err) {
      console.log(err)
    }
  }

  const onNameSaved = (id, newName) => {
    setdeviceChannelData(prevDeviceChannelData => {

      const updatedDeviceChannelData = prevDeviceChannelData.map(channel => {
        if (channel._id === id) {
          return { ...channel, name: newName };
        }
        return channel;
      });

      return updatedDeviceChannelData;
    });
  }

  function changeSearchSortOrderFilter(type, value) {
    switch (type) {
      case 'search':
        setsearchTerm(value)
        break;
      case 'sort':
        setsortBy(value)
        break;
      case 'order':
        setorderBy(value)
        break;
      case 'filter':
        setfilters((prevFilters) => ({
          ...prevFilters,
          [value]: !prevFilters[value]
        }))
        break;
      default:
        break;
    }
  }

  function changeSelectedMenuItem(itemIndex) { //set data to display in table
    if (tableLoading) return
    setsearchTerm("")
    setShowControlSettings(false)
    setselectedRecord({})
    getDataFromDatabase(itemIndex, true)
    setselectedMenuIndex(itemIndex)
  }

  async function getDataFromDatabase(tableIndex, setAsDisplayTable) { //get device + customer data from API
    setAsDisplayTable === true && settableLoading(true)
    const tabelIndexToCall = tableIndex === -1 ? selectedMenuIndex : tableIndex //-1 refresh current page
    switch (tabelIndexToCall) {
      case 0://CCM
        const ccmDevices = await getAllDevices("ccm")
        if (ccmDevices) {
          // const compiledDeviceData = compileAndSubscribeDashboardData(allDevices.items, deviceType1)
          setccmDevices(ccmDevices)
          // mqttSubscribeTo(0, ccmDevices) //for live status
        }
        break;
      case 1://HCC
        const hccDevices = await getAllDevices("hcc")
        if (hccDevices) {
          // const compiledDeviceData = compileAndSubscribeDashboardData(allDevices.items, deviceType1)
          sethccDevices(hccDevices)
          // mqttSubscribeTo(0, hccDevices) //for live status
        }
        break
      case 2://CASEM
        const casemDevices = await getAllDevices("casem")
        if (casemDevices) {
          // const compiledDeviceData = compileAndSubscribeDashboardData(allDevices.items, deviceType1)
          setcasemDevices(casemDevices)
          // mqttSubscribeTo(0, casemDevices) //for live status
        }
        break
      case 3://SIM fleet
        const allSims = await getAllSims()
        if (allSims) {
          setSims(allSims)
        }
        break
      case 4://Customers
        setgetCustomersLoading(true)
        const allCustomers = await getAllCustomers()
        if (allCustomers) {
          setcustomers(allCustomers)
        }
        setgetCustomersLoading(false)
        break;
      case 5:
        setgetFirmwareLoading(true)
        const allFirmware = await getAllFirmware();
        if (allFirmware) {
          setFirmware(allFirmware);
        }
        setgetFirmwareLoading(false);
      default:
        break;
    }
    settableLoading(false)
  }

  function toggleModal(shouldOpen, modalID) { //open/close for all modals
    const state = shouldOpen
    switch (modalID) {
      case 0:
        setshowDeviceModal(state)
        break;
      case 1:
        setshowDeviceModal(state)
        break;
      case 2:
        setshowDeviceModal(state)
        break;
      case 3:
        setShowSimsModal(state)
        break;
      case 4:
        setshowCustomerModal(state)
        break;
      case 5:
        setshowFirmwareModal(state);
      default:
        break;
    }
  }

  // CRUD
  function editClicked(recordData) {
    seteditRecordData(recordData)
    setmodalInEditMode(true)
    // let editType = selectedMenuIndex === 4 ? 4 : 0
    toggleModal(true, selectedMenuIndex)
  }
  function deleteClicked() {
    toggleConfirmAlert(true, 0)
  }

  function toggleConfirmAlert(show, type) {
    //Type: {0: delete, 1: settings}
    if (show) {
      setconfirmProps({ type })
    }
    setshowConfirmModal(show)
  }

  async function recordSelected(record) {
    if (record === null || record._id === selectedRecord._id) { //clear
      setselectedRecord({})
      setdeviceChannelDataLive({})
      setliveDeviceProps({})
      // setmqttMessagesArray([])
      return
    } else {
      setShowControlSettings(false)
      setselectedRecord(record)
      if (selectedMenuIndex === 0 || selectedMenuIndex === 1) { //CCM / HCC
        setdeviceChannelDataLoading(true)
        const deviceChannelDataResponse = await getDeviceChannelsById(record._id)
        setdeviceChannelData(deviceChannelDataResponse)
        setdeviceChannelDataLive({})
        setliveDeviceProps({})
        // setmqttMessagesArray([])
        // mqttSubscribeTo(1, record) //for live values
        setdeviceChannelDataLoading(false)
      }
    }
  }

  const handleShowEditChannelModal = (value, channelID, channelName) => {
    setShowEditChannelModal(value)
    setEditChannel({ channelID, channelName })
  }

  async function getDeviceGraphData(id, displayDate) {//Get and Compile
    const date = convertDateToIncludeOffset(displayDate)
    const deviceId = id
    let type = null
    let tempData = []
    let relayData = []
    let powerData = []
    let powerFactorData = []
    let dewPointData = []
    let dutyCycleData = []
    const telemetryRes = await getDeviceTelemetryById(deviceId, type, date)
    if (telemetryRes) {
      switch (selectedMenuIndex) {
        case 0://CCM
          tempData = telemetryRes.filter((record) => record.type === "ccm_control_temp")
          relayData = telemetryRes.filter((record) => record.type === "ccm_control_relay")
          powerData = telemetryRes.filter((record) => record.type === "ccm_inst_pwr")
          powerFactorData = telemetryRes.filter((record) => record.type === "ccm_pwr_fact")
          break;
        case 1://HCC
          tempData = telemetryRes.filter((record) => record.type === "hcc_temp")
          relayData = telemetryRes.filter((record) => record.type === "hcc_rel_stat")
          break;
        case 2://CASEM
          dewPointData = telemetryRes.filter((record) => record.type === "casem_dew_point")
          dutyCycleData = telemetryRes.filter((record) => record.type === "casem_duty_cycle")
          break;
        default:
          break;
      }
    }
    const compiledData = {
      tempData,
      relayData,
      powerData,
      powerFactorData,
      dewPointData,
      dutyCycleData
    }
    return compiledData
  }

  //MQTT stuff
  const mqttSubscriptionRef = useRef(null);
  const [mqttSubscribedState, setmqttSubscribedState] = useState(0);//{0:dashboard, 1:pullup overview, 2:full overview}

  function mqttSubscribeTo(state, data) {
    setmqttMessagesArray([]) //clear mqtt messages
    // subscribeDashboardTopics(mqttSubscriptionRef, state, data, handleMqttMessage)
    setmqttSubscribedState(state)
  }

  // useEffect(() => {
  //   if (mqttMessagesArray.length > 0) {
  //     switch (mqttSubscribedState) {
  //       case 0:
  //         compileMqttDashboardData(mqttMessagesArray)
  //         break;
  //       case 1:
  //         compileMqttOverviewData(mqttMessagesArray)
  //         break;
  //       case 2:
  //         compileMqttFullOverviewData(mqttMessagesArray)
  //         break;
  //       default:
  //         break;
  //     }

  //   }
  // }, [mqttMessagesArray]);

  function handleMqttMessage(messsageObj) {
    // setmqttMessagesArray(prev => {
    //   return [...prev, messsageObj].slice(-96) //only keep latest 96 messages
    // })
  }

  function compileMqttDashboardData(data) {
    if (selectedMenuIndex > 2) return
    const deviceIdsToUpdate = []
    let finalConnectedValues = {}
    let finalPowerStatValues = {}

    data.forEach(msg => { //get latest value for device status
      let deviceId = null
      if (msg?.['measureName'] === "pwrStat") {
        deviceId = msg.deviceID
        finalPowerStatValues[deviceId] = msg.value
      } else if ('eventType' in msg) {
        deviceId = msg.clientId
        finalConnectedValues[deviceId] = (msg['eventType'] === "connected")
      }
      if (!deviceIdsToUpdate.includes(deviceId)) {
        deviceIdsToUpdate.push(deviceId)
      }
    });

    let copyDeviceData = JSON.parse(JSON.stringify(tableDataArray[selectedMenuIndex]))
    deviceIdsToUpdate.forEach(deviceId => {
      const deviceObjIndex = copyDeviceData.findIndex(device => device.thingName === deviceId)

      if (deviceObjIndex > -1) {///if device in current tableData
        if (deviceId in finalConnectedValues) {
          const connected = finalConnectedValues[deviceId]
          copyDeviceData[deviceObjIndex] = { ...copyDeviceData[deviceObjIndex], connected }
        }
        if (deviceId in finalPowerStatValues) {
          const pwrStat = finalPowerStatValues[deviceId]
          copyDeviceData[deviceObjIndex] = { ...copyDeviceData[deviceObjIndex], pwrStat }
        }
      }
    });
    switch (selectedMenuIndex) {
      case 0:
        setccmDevices(copyDeviceData)
        break;
      case 1:
        sethccDevices(copyDeviceData)
        break;
      case 2:
        setcasemDevices(copyDeviceData)
        break;

      default:
        break;
    }
  }

  function compileMqttOverviewData(data) {
    if (selectedMenuIndex === 0) {//----------------------------------------------CCM
      let finalValues = {} //{type:value}
      data.forEach(msg => { //get latest value for device status
        if ('measureName' in msg) {
          const measurementName = msg?.measureName
          const measurementValue = msg?.value
          finalValues[measurementName] = measurementValue
        }
      });
      setdeviceChannelDataLive(prev => ({ ...prev, ...finalValues }))
    } else {//---------------------------------------------------------------HCC and other
      let finalTemps = {} //{index:value}
      let finalRels = {} //{index:value}
      data.forEach(msg => { //get latest value for device status
        const measurementName = msg?.measureName
        const measureIndex = measurementName?.slice(-1)
        if (measurementName?.includes('temp')) {
          finalTemps[measureIndex - 1] = msg.value
        }
        if (measurementName?.includes('relStat')) {
          finalRels[measureIndex - 1] = msg.value
        }
      });
      let copyDeviceChannelData = JSON.parse(JSON.stringify(deviceChannelData))
      Object.keys(finalTemps).forEach(channelIndex => {
        const tempValue = finalTemps[channelIndex]
        copyDeviceChannelData[channelIndex] = { ...copyDeviceChannelData[channelIndex], temp: tempValue }
      });
      Object.keys(finalRels).forEach(channelIndex => {
        const relStatValue = finalRels[channelIndex]
        copyDeviceChannelData[channelIndex] = { ...copyDeviceChannelData[channelIndex], relState: relStatValue }
      });
      setdeviceChannelData(copyDeviceChannelData)
    }
  }

  function compileMqttFullOverviewData(data) {
    let finalValues = {} //{type:value}
    data.forEach(msg => { //get latest value for device status
      if ('measureName' in msg) {
        const measurementName = msg?.measureName
        const measurementValue = msg?.value
        finalValues[measurementName] = measurementValue
      }

    });
    setliveDeviceProps(prev => ({ ...prev, ...finalValues }))
  }

  function mqttUnsubscribeFrom() {
  }

  function toggleAlert(show, alertType, message) {
    //Type: {0: red, 1: blue, 2: green}
    if (show) {
      setalertProps({ alertType, message })
    }
    setshowCustomAlert(show)
  }

  const hasSerialNumber = (serial) => {
    if (serial !== null && serial !== undefined) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className='dashboard-container'>
      {/* Alerts + Modals */}
      <CustomAlert
        show={showCustomAlert}
        props={alertProps}
        toggle={toggleAlert}
      />
      <EditChannelModal
        show={showEditChannelModal}
        handleShowEditChannelModal={handleShowEditChannelModal}
        editChannel={editChannel}
        onNameSaved={onNameSaved}
      />
      <AddEditDevice
        show={showDeviceModal}
        toggleModal={toggleModal}
        modalInEditMode={modalInEditMode}
        selectedRecord={editRecordData}
        deleteClicked={deleteClicked}
        customers={customers}
        toggleAlert={toggleAlert}
        getDataFromDatabase={getDataFromDatabase}
        getCustomersLoading={getCustomersLoading}
      />
      <AddEditCustomer
        show={showCustomerModal}
        toggleModal={toggleModal}
        modalInEditMode={modalInEditMode}
        selectedRecord={editRecordData}
        deleteClicked={deleteClicked}
        toggleAlert={toggleAlert}
        getDataFromDatabase={getDataFromDatabase}
      />
      <AddEditFirmware
        show={showFirmwareModal}
        toggleModal={toggleModal}
        modalInEditMode={modalInEditMode}
        selectedRecord={editRecordData}
        deleteClicked={deleteClicked}
        toggleAlert={toggleAlert}
        getDataFromDatabase={getDataFromDatabase}
      />
      {/* Layout Components */}
      <SideNav
        setShowControlSettings={setShowControlSettings}
        selectedMenuIndex={selectedMenuIndex}
        changeSelectedMenuItem={changeSelectedMenuItem}
        handleSignOut={handleSignOut}
        navigate={navigate}
        profileName={profileName}
      />
      <div className='dashboard-body'
        id={(selectedRecord?._id !== undefined && menuItems[selectedMenuIndex].pullupEnabled && hasSerialNumber(selectedRecord.serialNumber))
          ? "slide-contract"
          : "slide-expand"
        }
      >
        <TopToolbar
          selectedMenuIndex={selectedMenuIndex}
          searchTerm={searchTerm}
          orderBy={orderBy}
          sortBy={sortBy}
          filters={filters}
          changeSearchSortOrderFilter={changeSearchSortOrderFilter}
          toggleModal={toggleModal}
        />
        <DataTable
          tableLoading={tableLoading}
          selectedMenuIndex={selectedMenuIndex}
          editClicked={editClicked}
          selectedListData={tableDataArray[selectedMenuIndex]}
          selectedRecord={selectedRecord}
          recordSelected={recordSelected}
          searchTerm={searchTerm}
          sortBy={sortBy}
          orderBy={orderBy}
          filters={filters}
          setShowControlSettings={setShowControlSettings}
          setSelectedSettingsRecord={setSelectedSettingsRecord}
        />
      </div>
      {(menuItems[selectedMenuIndex].pullupEnabled && hasSerialNumber(selectedRecord.serialNumber)) &&
        <PullupContainer
          toggleAlert={toggleAlert}
          mqttMessagesArray={mqttMessagesArray}
          setmqttMessagesArray={setmqttMessagesArray}
          selectedMenuIndex={selectedMenuIndex}
          selectedRecord={selectedRecord}
          deviceChannelDataLoading={deviceChannelDataLoading}
          deviceChannelData={deviceChannelData}
          deviceChannelDataLive={deviceChannelDataLive}
          liveDeviceProps={liveDeviceProps}
          getDeviceGraphData={getDeviceGraphData}
          mqttSubscribeTo={mqttSubscribeTo}
          mqttUnsubscribeFrom={mqttUnsubscribeFrom}
          handleShowEditChannelModal={handleShowEditChannelModal}
          showControlSettings={showControlSettings}
        />
      }
    </div>
  )
}

export default protectedRoute(Dashboard)
