export const dataColors =
{
    1: "steelblue",
    2: "orange",
    3: 'green',
    4: "red",
    5: "#8e489b",
    6: "#edb020",
    7: "#d8531a",
    8: "#0072bc"
}
