import { useEffect, useState, useRef } from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ClipLoader } from "react-spinners";

const TableRow = ({
  keyValue,
  settings,
  settingsValues,
  newSettingsValues,
  setNewSettingsValues,
  settingsLoaders,
  setSettingsLoaders,
  settingsLoadersColors
}) => {
  const [settingChanging, setSettingChanging] = useState()
  const [loadError, setLoadError] = useState(false)
  const [localLoader, setLocalLoader] = useState(null)

  const handleChange = (input) => {
    setSettingChanging(true)
    const inputConvert = parseInt(input)
    setNewSettingsValues(prev => ({ ...prev, [keyValue]: inputConvert }))
  }


  useEffect(() => {
    if (settingsLoaders[keyValue] !== localLoader) {
      setLocalLoader(settingsLoaders[keyValue])
    }
  }, [settingsLoaders])

  const loaderTimeout = useRef(null)
  useEffect(() => {
    if (localLoader === null) return


    if (localLoader === true) {
      loaderTimeout.current = setTimeout(() => {
        setLoadError(true)
      }, 5000);
    } else {
      clearTimeout(loaderTimeout.current)
    }
  }, [localLoader])

  const generateLoaders = () => {
    if (loadError) {
      return <ErrorOutlineIcon sx={{ height: '100%', color: 'red' }} />
    } else {
      if (!settingsLoaders[keyValue] || settingsLoaders[keyValue] === null) {
        return <CheckCircleOutlineIcon sx={{ height: '100%', color: settingsLoadersColors[keyValue] }} />
      } else {
        return <ClipLoader size={18} />
      }
    }
  }

  return (
    <div className='row' >
      <div className='column'>
        <p>
          {settings[keyValue].alias}
        </p>
      </div>
      <div className='column'>
        {settingsValues[keyValue] + settings[keyValue].unit}
      </div>
      <div className='column'>
        <input
          type={'number'}
          min={settings[keyValue].min}
          max={settings[keyValue].max}
          value={
            !settingChanging
              ? settingsValues[keyValue] ?? ''
              : newSettingsValues[keyValue] ?? ''
          }
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      <div className='column'>
        <div>
          {generateLoaders()}
        </div>
      </div>

    </div>
  )
}

export default TableRow;
