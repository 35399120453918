import React, { useState, useEffect } from 'react'
import './styles/PullupContainer.css'
import LogsContainer from './PullupContainer/LogsContainer';
import StatisticsContainer from './PullupContainer/StatisticsContainer';
import SimCardContainer from './PullupContainer/SimCardContainer'
import { getDataVariance, isLater, isSameDay } from './GraphComponent/Functions';
import TabNavigator from './PullupContainer/TabNavigator';
import ControlSettings from './ControlSettings';
import LiveDataContainer from './PullupContainer/LiveDataContainer';
import { Provider } from 'react-redux';
import { statisticsStore } from '../stores/statisticsStore';

export default function PullupContainer({
  toggleAlert,
  mqttMessagesArray,
  setmqttMessagesArray,
  selectedMenuIndex,
  selectedRecord,
  deviceChannelDataLoading,
  deviceChannelData,
  deviceChannelDataLive,
  liveDeviceProps,
  getDeviceGraphData,
  mqttSubscribeTo,
  mqttUnsubscribeFrom,
  handleShowEditChannelModal,
  showControlSettings
}) {


  const deviceType = selectedMenuIndex// {0: CCM, 1: HCC, 2: CASEM}

  const deviceDetails = deviceType === 1 ? [
    { title: "Serial No", value: selectedRecord?.serialNumber },
    { title: "Screen State", value: liveDeviceProps?.scrnStat === 1 ? "Off" : "On" }, //null screen state
    { title: "Firmware Version", value: selectedRecord?.firmwareVersion }
  ] : [
    { title: "Serial No", value: selectedRecord?.serialNumber },
    { title: "Firmware Version", value: selectedRecord?.firmwareVersion },
    { title: "Ambient RH", value: liveDeviceProps?.ambHum ? liveDeviceProps?.ambHum + "%" : "N/A" },
    { title: "Ambient Temp", value: liveDeviceProps?.ambTemp ? liveDeviceProps?.ambTemp + '\u00b0C' : "N/A" },
  ]

  //UI animation vars
  const [slideStateId, setslideStateId] = useState("");
  const [subSlideStateId, setsubSlideStateId] = useState("");
  //Date vars
  const [displayDate, setdisplayDate] = useState("");
  const [dateUpdatedFlag, setdateUpdatedFlag] = useState(false);

  //Visual display data
  const [tempDisplayData, settempDisplayData] = useState([]); //Temp (HCC && CASEM)
  const [relayDisplayData, setrelayDisplayData] = useState([]); //Relay (HCC)
  const [powerDisplayData, setpowerDisplayData] = useState([]);
  const [powerFactorDisplayData, setpowerFactorDisplayData] = useState([]);

  //Visibility
  const [tempTracersVisible, settempTracersVisible] = useState({ 1: true, 2: true, 3: true, 4: true, 5: true, 6: true, 7: true, 8: true });
  const [relayBarsVisible, setrelayBarsVisible] = useState({ 1: true, 2: true, 3: true, 4: true, 5: true, 6: true, 7: true, 8: true });
  const [targetOffsetsVisible, settargetOffsetsVisible] = useState({ 1: true, 2: true, 3: true, 4: true, 5: true, 6: true, 7: true, 8: true });
  const [powerTracersVisible, setpowerTracersVisible] = useState({ 1: true, 2: true, 3: true, 4: true, 5: true });
  //Loaders
  const [graphLoading, setgraphLoading] = useState(true);
  //Graph vars
  const [tempAxisConstants, settempAxisConstants] = useState({ max: 10, min: 0 });
  const [powerAxisConstants, setpowerAxisConstants] = useState({ max: 10, min: 0 });

  const [tempGraphComponentValues, settempGraphComponentValues] = useState({});
  const [relayGraphComponentValues, setrelayGraphComponentValues] = useState({});
  const [targetOffsetComponentValues, settargetOffsetComponentValues] = useState([]);
  const [powerGraphComponentValues, setpowerGraphComponentValues] = useState({});
  const [powerFactorGraphComponentValues, setpowerFactorGraphComponentValues] = useState({});

  const [headerValue, setheaderValue] = useState("Device Data");
  const [selectedDisplayTab, setselectedDisplayTab] = useState(0); //(0:live data, 1:Statistics, 2:Terminal )
  const [displayDataUpdated, setdisplayDataUpdated] = useState(false); //trigger updating graph display

  //Statistics VARS
  const [finalStartDateA, setFinalStartDateA] = useState("");
  const [finalEndDateA, setFinalEndDateA] = useState("");
  const [finalGranularityA, setfinalGranularityA] = useState("HOURLY");
  const [datasetDisplayDetailsA, setdatasetDisplayDetailsA] = useState([]); //selected datasets display values {datasetId,type,color}
  const [datasetsA, setdatasetsA] = useState({});//{datsetId:[]}
  const [graphComponentValuesA, setgraphComponentValuesA] = useState({});//{datsetId:[]}
  const [yAxisConstantsStateA, setyAxisConstantsStateA] = useState({ min: 0, max: 0 });
  const [claimedDatasetColorsA, setclaimedDatasetColorsA] = useState([]);
  const [unitIdInUseA, setunitIdInUseA] = useState(null);// C / Sec / Min / W / kWh / %
  const [finalStartDateB, setFinalStartDateB] = useState("");
  const [finalEndDateB, setFinalEndDateB] = useState("");
  const [finalGranularityB, setfinalGranularityB] = useState("HOURLY");
  const [datasetDisplayDetailsB, setdatasetDisplayDetailsB] = useState([]); //selected datasets display values {datasetId,type,color}
  const [datasetsB, setdatasetsB] = useState({});//{datsetId:[]}
  const [graphComponentValuesB, setgraphComponentValuesB] = useState({});//{datsetId:[]}
  const [yAxisConstantsStateB, setyAxisConstantsStateB] = useState({ min: 0, max: 0 });
  const [claimedDatasetColorsB, setclaimedDatasetColorsB] = useState([]);
  const [unitIdInUseB, setunitIdInUseB] = useState(null);// C / Sec / Min / W / kWh / %

  const [syncLocked, setsyncLocked] = useState(false);
  const [syncedZoom, setsyncedZoom] = useState(100);
  const [syncedTranslateX, setsyncedTranslateX] = useState(0);

  useEffect(() => {
    const today = new Date()
    setFinalStartDateA(today)
    setFinalEndDateA(today)
    setFinalStartDateB(today)
    setFinalEndDateB(today)
  }, []);

  useEffect(() => {
    initInterface()
  }, [selectedRecord, showControlSettings]);

  useEffect(() => { //Get graph data on date changes
    if (dateUpdatedFlag) {
      requestDeviceDataOnDate()
      setdateUpdatedFlag(false)
    }
  }, [dateUpdatedFlag]);

  function initInterface() { //init animation slide state + displayDate + headerValue
    if (selectedRecord?._id && !showControlSettings) { //row selected
      selectedDisplayTab !== 0 && setselectedDisplayTab(0)
      if ((slideStateId === "") || (slideStateId === "show-hide")) {//show if hidden
        setslideStateId("hide-show")
      }
      const today = new Date()
      setdisplayDate(today)
      if (selectedRecord?.serialNumber) {
        setheaderValue(selectedRecord.serialNumber)
      } else {
        setheaderValue("Device Data")
      }
    } else { //no row selected
      if ((slideStateId === "slide-down") || (slideStateId === "hide-show") || (showControlSettings === true)) {//hide if shown
        setslideStateId("show-hide")
      } else {
        setslideStateId("show-hide")
        setsubSlideStateId("slide-down") //body
      }
    }
  }

  // toggle animation state
  function toggleContainerState() {
    if (slideStateId === "slide-up") {
      // unsubscribeFrom("overview")
      setselectedDisplayTab(0)
      setslideStateId("slide-down") //full container
      setsubSlideStateId("slide-down") //body
    } else {
      mqttSubscribeTo(2, selectedRecord)
      setslideStateId("slide-up") //full container
      setsubSlideStateId("slide-up") //body
      requestDeviceDataOnDate()
    }
  }

  async function requestDeviceDataOnDate() {//Request device data from API
    setgraphLoading(true)

    const deviceDisplayData = await getDeviceGraphData(selectedRecord._id, displayDate)
    // console.log(deviceDisplayData)
    if (deviceDisplayData?.tempData.length > 0) { //temp
      const valueKey = deviceType === 0 ? 'ctlTmp' : 'temp'
      const tempVariance = getDataVariance(deviceDisplayData.tempData, valueKey)
      settempAxisConstants({ min: tempVariance.minValue, max: tempVariance.maxValue })
      settempDisplayData(deviceDisplayData.tempData)
    } else {
      settempDisplayData([])
    }
    if (deviceDisplayData?.powerData.length > 0) {//power
      const valueKey = deviceType === 0 ? 'instPwr' : null
      const powerVariance = getDataVariance(deviceDisplayData.powerData, valueKey)
      setpowerAxisConstants({ min: powerVariance.minValue, max: powerVariance.maxValue })
      setpowerDisplayData(deviceDisplayData.powerData)
    } else {
      setpowerDisplayData([])
    }
    if (deviceDisplayData?.powerFactorData.length > 0) {//power factor
      setpowerFactorDisplayData(deviceDisplayData.powerFactorData)
    } else {
      setpowerFactorDisplayData([])
    }
    if (deviceDisplayData?.relayData.length > 0) {//relay
      setrelayDisplayData(deviceDisplayData.relayData)
    } else {
      setrelayDisplayData([])
    }

    setdisplayDataUpdated(true)
    setgraphLoading(false)
  }

  function toggleDataVisibility(id, type, allVisibiltyState) {//checkbox toggles
    let updatedObjectValue = {}
    const updateVisibilityValues = (visibilityValueArray) => {
      if (id === 100) {
        Object.keys(visibilityValueArray).forEach(key => {
          updatedObjectValue[key] = !allVisibiltyState
        });
      } else {
        updatedObjectValue = { ...visibilityValueArray, [id]: !visibilityValueArray[id] }
      }
    }
    switch (type) {
      case "temp":
        updateVisibilityValues(tempTracersVisible)
        settempTracersVisible(updatedObjectValue)
        break;
      case "relay":
        updateVisibilityValues(relayBarsVisible)
        setrelayBarsVisible(updatedObjectValue)
        break;
      case "target":
        updateVisibilityValues(targetOffsetsVisible)
        settargetOffsetsVisible(updatedObjectValue)
        break;
      case "power":
        updateVisibilityValues(powerTracersVisible)
        setpowerTracersVisible(updatedObjectValue)
        break;
      default:
        break;
    }
  }

  const allIndicatorsVisibleFor = (visibileStateArray) => {
    let visible = true
    Object.keys(visibileStateArray).forEach(key => {
      if (visibileStateArray[key] === false) {
        visible = false
      }
    });
    return visible
  }



  const displayCurrentTab = () => {
    switch (selectedDisplayTab) {
      case 0:
        return (
          <LiveDataContainer
            selectedRecord={selectedRecord}
            displayDataUpdated={displayDataUpdated}
            setdisplayDataUpdated={setdisplayDataUpdated}
            deviceType={deviceType}
            displayDate={displayDate}
            tempTracersVisible={tempTracersVisible}
            relayBarsVisible={relayBarsVisible}
            targetOffsetsVisible={targetOffsetsVisible}
            powerTracersVisible={powerTracersVisible}
            toggleDataVisibility={toggleDataVisibility}
            tempDisplayData={tempDisplayData}
            relayDisplayData={relayDisplayData}
            powerDisplayData={powerDisplayData}
            powerFactorDisplayData={powerFactorDisplayData}
            graphLoading={graphLoading}
            tempAxisConstants={tempAxisConstants}
            powerAxisConstants={powerAxisConstants}
            liveDeviceProps={liveDeviceProps}
            deviceChannelDataLive={deviceChannelDataLive}
            settempGraphComponentValues={settempGraphComponentValues}
            tempGraphComponentValues={tempGraphComponentValues}
            relayGraphComponentValues={relayGraphComponentValues}
            setrelayGraphComponentValues={setrelayGraphComponentValues}
            targetOffsetComponentValues={targetOffsetComponentValues}
            settargetOffsetComponentValues={settargetOffsetComponentValues}
            powerGraphComponentValues={powerGraphComponentValues}
            setpowerGraphComponentValues={setpowerGraphComponentValues}
            powerFactorGraphComponentValues={powerFactorGraphComponentValues}
            setpowerFactorGraphComponentValues={setpowerFactorGraphComponentValues}
            deviceDetails={deviceDetails}
            selectedMenuIndex={selectedMenuIndex}
            deviceChannelDataLoading={deviceChannelDataLoading}
            deviceChannelData={deviceChannelData}
            headerValue={headerValue}
            handleShowEditChannelModal={handleShowEditChannelModal}
            slideStateId={slideStateId}
            setdateUpdatedFlag={setdateUpdatedFlag}
            selectedDisplayTab={selectedDisplayTab}
            allIndicatorsVisibleFor={allIndicatorsVisibleFor}
            setdisplayDate={setdisplayDate}
          />
        )
      case 1:
        return (
          <Provider
            store={statisticsStore}
          >
            <StatisticsContainer
              selectedRecord={selectedRecord}
              headerValue={headerValue}
              toggleAlert={toggleAlert}
            />
          </ Provider >
        )
      case 2:
        return (<LogsContainer
          selectedRecord={selectedRecord}
          serialNumber={selectedRecord?.serialNumber}
          mqttMessagesArray={mqttMessagesArray} />)
      case 3:
        return (
          <SimCardContainer
            record={selectedRecord}
            toggleAlert={toggleAlert}
          />
        )
      case 4:
        return (
          <ControlSettings
            record={selectedRecord}
            toggleAlert={toggleAlert}
          />)
      default:
        break;
    }
  }

  return (
    <div className='overview-container' id={slideStateId}>
      <div className='pull-up-toggle-container' style={{ height: slideStateId === "slide-up" ? "60px" : "30px" }}>
        <div className='toggle-button-container' onClick={() => toggleContainerState()} >
          <svg className='pull-up-toggle-btn' height="10" width="30" xmlns="http://www.w3.org/2000/svg">
            <polygon points={slideStateId === "slide-up" ? "0,0 15,10 30,0" : "0,10 15,0 30,10"} fill='#ffffff93' />
          </svg>
        </div>
        {(slideStateId === "slide-up") &&
          <TabNavigator
            selectedDisplayTab={selectedDisplayTab}
            setselectedDisplayTab={setselectedDisplayTab} />
        }
      </div>
      <div className='overview-container-body' id={"sub-" + subSlideStateId}>
        {displayCurrentTab()}
      </div>
    </div>
  )
}
