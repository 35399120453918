export const statTopics = [
  {
    title: 'Control Statistics',
    values: [
      { id: 'control-0', name: 'Avg Control Temp', valueKey: 'avgConTmp', value: 'N/A', unit: 0, type: 'ccm_avg_con_tmp' },
      { id: 'control-1', name: 'Control Variance', valueKey: 'conTmpDiff', value: 'N/A', unit: 0, type: 'ccm_con_tmp_diff' },
      { id: 'control-2', name: 'Avg Cycle Period', valueKey: 'avgCycPer', value: 'N/A', unit: 1, type: 'ccm_avg_cyc_per' },
      { id: 'control-3', name: 'Avg Duty Cycle', valueKey: 'avgCycDuty', value: 'N/A', unit: 3, type: 'ccm_avg_cyc_duty' }
    ]
  },
  {
    title: 'Defrost Statistics',
    values: [
      { id: 'defrost-0', name: 'Defrost Time', valueKey: 'avgDefTime', value: 'N/A', unit: 2, type: 'ccm_avg_def_time' },
      { id: 'defrost-1', name: 'Max Defrost Temp', valueKey: 'maxMaxDefTmp', value: 'N/A', unit: 0, type: 'ccm_avg_max_def_tmp' },
      { id: 'defrost-2', name: 'Avg Defrost Temp', valueKey: 'avgMaxDefTmp', value: 'N/A', unit: 0, type: 'ccm_avg_def_int' }
    ]
  },
  {
    title: 'Energy Statistics',
    values: [
      { id: 'energy-0', name: 'On-state Consumption', valueKey: 'avgOnStatPwr', value:  'N/A', unit: 4, type: 'ccm_avg_on_stat_pwr' },
      { id: 'energy-1', name: 'Off-state Consumption', valueKey: 'avgOffStatPwr', value:   'N/A', unit: 4, type: 'ccm_avg_off_stat_pwr' },
      { id: 'energy-2', name: 'Avg Power', valueKey: 'avgPwr', value: 'N/A', unit: 4, type: 'ccm_avg_pwr' },
      { id: 'energy-3', name: 'Total Daily Usage', valueKey: 'totEngHrly', value: 'N/A', unit: 5, type: 'ccm_tot_eng_hrly' }
    ]
  },
  {
    title: 'Ambient Statistics',
    values: [
      { id: 'ambient1-0', name: 'Avg Temp', valueKey: 'avgAmbTemp', value: 'N/A', unit: 0, type: 'ccm_avg_amb_temp'},
      { id: 'ambient1-1', name: 'Temp (Max / Min)', valueKey: '', value: 'N/A', unit: 0 },
      { id: 'ambient1-2', name: 'Avg Humidity', valueKey: 'avgAmbHum', value: 'N/A', unit: 3 },
      { id: 'ambient1-3', name: 'Humidity (Max / Min)', valueKey: '', value: 'N/A', unit: 3 }
    ]
  },
  {
    title: 'Ambient Statistics',
    values: [
      { id: 'ambient2-0', name: 'Avg Ambient Temp', valueKey: 'avgAmbTemp', value: 'N/A', unit: 0, type: 'ccm_avg_amb_temp' },
      { id: 'ambient2-1', name: 'Max Temp', valueKey: '', value: 'N/A', unit: 0, type: '' },
      { id: 'ambient2-2', name: 'Min Temp', valueKey: '', value: 'N/A', unit: 0, type: '' },
      { id: 'ambient2-3', name: 'Avg Humidity', valueKey: 'avgAmbHum', value: 'N/A', unit: 3, type: 'ccm_avg_amb_hum' },
      { id: 'ambient2-4', name: 'Max Humidity', valueKey: '', value: 'N/A', unit: 3, type: '' },
      { id: 'ambient2-5', name: 'Min Humidity', valueKey: '', value: 'N/A', unit: 3, type: '' }
    ]
  }
];


export const statDatasets = {
  control: [
    { id: 'control-0', name: "Avg Control Temp", unitId: 0, type: 'ccm_avg_con_tmp' },
    { id: 'control-1', name: "Control Variance", unitId: 0, type: 'ccm_con_tmp_diff' },
    { id: 'control-2', name: "Avg Cycle Period", unitId: 1, type: 'ccm_avg_cyc_per' },
    { id: 'control-3', name: "Avg Duty Cycle", unitId: 3, type: 'ccm_avg_cyc_duty' }
  ],
  defrost: [
    { id: 'defrost-0', name: "Defrost Time", unitId: 2, type: 'ccm_avg_def_time' },
    { id: 'defrost-1', name: "Max defrost Temp", unitId: 0, type: 'ccm_avg_max_def_tmp' },
    { id: 'defrost-2', name: "Avg defrost Temp", unitId: 0, type: 'ccm_avg_def_int' }
  ],
  energy: [
    { id: 'energy-0', name: "On-state consumption", unitId: 4, type: 'ccm_avg_on_stat_pwr' },
    { id: 'energy-1', name: "Off-state consumption", unitId: 4, type: 'ccm_avg_off_stat_pwr' },
    { id: 'energy-2', name: "Avg Power", unitId: 4, type: 'ccm_avg_pwr' },
    { id: 'energy-3', name: "Total Daily Usage", unitId: 5, type: 'ccm_tot_eng_hrly' }
  ],
  ambient1: [
    { id: 'ambient1-0', name: "Avg Temp", unitId: 0 },
    { id: 'ambient1-1', name: "Temp (Max / Min)", unitId: 0 },
    { id: 'ambient1-2', name: "Avg Humidity", unitId: 3 },
    { id: 'ambient1-3', name: "Humidity (Max / Min)", unitId: 3 }
  ],
  ambient2: [
    { id: 'ambient2-0', name: "Avg Ambient Temp", unitId: 0, type: 'ccm_avg_amb_temp' },
    { id: 'ambient2-1', name: "Max Temp", unitId: 0, type: '' },
    { id: 'ambient2-2', name: "Min Temp", unitId: 0, type: '' },
    { id: 'ambient2-3', name: "Avg Humidity", unitId: 3, type: 'ccm_avg_amb_hum' },
    { id: 'ambient2-4', name: "Max Humidity", unitId: 3, type: '' },
    { id: 'ambient2-5', name: "Min Humidity", unitId: 3, type: '' }
  ]
}

export const unitValues = {
  0: { unit: '\u00b0C', title: 'Temperature' },
  1: { unit: 'sec', title: 'Time' },
  2: { unit: 'min', title: 'Time' },
  3: { unit: '%', title: 'Percent' },
  4: { unit: 'W', title: 'Power' },
  5: { unit: 'kWh', title: 'Cummulative Energy' },
}
