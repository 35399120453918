import React, { useEffect, useState } from 'react'
import './styles/TableRow.css'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ReactComponent as PowerOffIcon } from '../../assets/PowerOffIcon.svg';
import { ReactComponent as DisconnectedIcon } from '../../assets/DisconnectedIcon.svg'
import { ReactComponent as PowerOnIcon } from '../../assets/PowerOnIcon.svg'
import SettingsIcon from '@mui/icons-material/Settings';
import { ClipLoader } from 'react-spinners';
import { menuItems } from '../../constants/dataVars';
import { getSingletonPubSub } from '../../mqtt/MyPubSub';

export default function TableRow({
  selectedMenuIndex,
  columnData,
  isSelected,
  recordSelected,
  record,
  editClicked,
}) {
  const deviceId = record.thingName
  const deviceType = record.type
  const [localRecord, setLocalRecord] = useState(record)
  const statusIcons = [
    <PowerOffIcon className='icon'/>,
    <PowerOnIcon className='icon'/>,
    <DisconnectedIcon className='icon'/>
  ]

  // Topics For Status
  const topics = [
    `device/${deviceType}/${deviceId}/telemetry/${'pwrStat'}`,
    `$aws/events/presence/+/${deviceId}`
  ]

  useEffect(() => {
    let subscriptions;
    const getStatusValues = async () => {
      const pubsub = await getSingletonPubSub()
      subscriptions = topics.map((topic, index) => {
        return pubsub.subscribe({
          topics: [topic]
        }).subscribe({
          next: (data) => {
            if (topic.endsWith('pwrStat')) {
              setLocalRecord((prev) => ({
                ...prev,
                pwrStat: data.value
              }));
            } else {
              setLocalRecord((prev) => ({
                ...prev,
                connected: data.eventType === 'connected' ? true : false
              }));
            }
          },
          error: (error) => console.error(error),
          comaplete: () => console.log('done')
        })

      })
    }

    getStatusValues()
    return () => {
      if (subscriptions) {
        subscriptions.forEach(sub => {
          sub.unsubscribe()
        });
      }
    }
  }, [])


  const getStatusIcon = () => {
    let statusLoaded = (localRecord['connected'])
    let iconIndex = (localRecord['connected'] ? 1 : 2)
    if (!statusLoaded && ('pwrStat' in localRecord)) {
      statusLoaded = true
      iconIndex = localRecord['pwrStat'] === 1 ? 2 : 0
    }
    return (<div className='status-icon'>
      {
        !statusLoaded ?
          <ClipLoader
            size={15}
            color="#2d928c"
          /> :
          statusIcons[iconIndex]
      }</div>)
  }

  function formatReadableDate(isoDate) {
    // Create a Date object
    const date = new Date(isoDate);

    // Convert to SAST (UTC+2)
    const offset = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
    const sastDate = new Date(date.getTime() + offset);

    // Extract the parts of the date
    const day = String(sastDate.getUTCDate()).padStart(2, '0');
    const month = String(sastDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = sastDate.getUTCFullYear();
    const hours = sastDate.getUTCHours();
    const minutes = String(sastDate.getUTCMinutes()).padStart(2, '0');

    // Return the formatted date
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  return (
    <div className='table-row'>
      {columnData.map((columnObj, index) => {
        let recordValue = record[columnObj.columnKey]
        if (columnObj.columnKey === 'thingName') {
          recordValue = record[columnObj.columnKey]?.toString().substring(0, 7);
        }
        if (columnObj.columnKey === 'customerName') {
          recordValue = record?.customer?.name
        }
        if (columnObj.columnKey === 'createdAt') {
          recordValue = formatReadableDate(record[columnObj.columnKey])
        }
        return (
          <div key={index} onClick={() => recordSelected(record)}
            className={`table-row-column ${columnObj.columnKey === 'status' && 'status-value'} ${columnObj.columnKey === 'thingName' && 'id-value'}`}
            style={{ flex: columnObj.widthIndex, backgroundColor: isSelected ? "#6285b366" : 'transparent' }}>
            {index === columnData.length - 1 ?
              <div className='final-container'>
                <p style={{ fontWeight: columnObj.columnKey === "name" ? 600 : 400 }}> {recordValue} {columnObj.suffix}</p>
                <div className='button-container'>
                  {menuItems[selectedMenuIndex].showEditButton && (
                    <div className='tooltip-icon'>
                      <EditOutlinedIcon onClick={(e) => [e.stopPropagation(), editClicked(record, 0)]} className='edit-button' sx={{ color: 'gray' }} />
                      <span className='tooltip-text'>Edit</span>
                    </div>
                  )}
                </div>
              </div>
              :
              <>
                {(columnObj.columnKey === "status") ?
                  (getStatusIcon())
                  :
                  <p style={{ fontWeight: columnObj.columnKey === "name" ? 600 : 400 }}>
                    {(recordValue) === ''
                      || (recordValue) === null ? '-' : columnObj.enums ? columnObj.enums[recordValue] : recordValue
                    }

                  </p>
                }
              </>
            }
          </div>
        )
      })}
    </div >
  )
}
