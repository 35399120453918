import React from 'react'
import DatePicker from "react-datepicker";
import { subDays } from "date-fns";
import GraphContainer from '../GraphComponent/GraphContainer';
import 'react-datepicker/dist/react-datepicker.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CollapsedOverviewCard from '../PullupContainer/CollapsedOverviewCard';
import ExpandedOverviewCard from '../PullupContainer/ExpandedOverviewCard';
import { PuffLoader } from 'react-spinners';
import CustomCheckBox from '../CustomCheckBox';
import { dataColors } from '../../constants/colors';
import { iccOverviewData } from '../../constants/dataVars';
import PullupCard from './PullupCard';
import LiveDataCard from './components/LiveDataCard';

export default function LiveDataContainer({
  selectedRecord,
  deviceType,
  displayDate,
  tempTracersVisible,
  relayBarsVisible,
  targetOffsetsVisible,
  powerTracersVisible,
  toggleDataVisibility,
  tempDisplayData,
  relayDisplayData,
  powerDisplayData,
  powerFactorDisplayData,
  graphLoading,
  liveDeviceProps,
  deviceChannelDataLive,
  deviceDetails,
  selectedMenuIndex,
  deviceChannelDataLoading,
  deviceChannelData,
  headerValue,
  handleShowEditChannelModal,
  slideStateId,
  setdateUpdatedFlag,
  selectedDisplayTab,
  allIndicatorsVisibleFor,
  setdisplayDate
}) {

  const displayLiveValue = (value, cardState) => {
    let valueFinal = 'N/A'
    const liveDataObj = cardState === "expanded" ? liveDeviceProps : deviceChannelDataLive
    if (liveDataObj[value.valueKey] || liveDataObj[value.valueKey] === 0) {
      if ('values' in value) {
        valueFinal = value.values[liveDataObj[value.valueKey]]
      } else {
        valueFinal = liveDataObj[value.valueKey].toFixed(2) + value.unit
      }
    }
    return valueFinal
  }

  return (
    <>
      <div className='overview-card-container' target={`_${selectedMenuIndex}`}>
        {(slideStateId === "slide-up") &&
          ((selectedMenuIndex === 1) ?
            <div className='top-display-select-container'>
              <div className='row toggle-visibility-container'>
                <CustomCheckBox
                  onChange={() => toggleDataVisibility(100, "temp", allIndicatorsVisibleFor(tempTracersVisible))}
                  checked={allIndicatorsVisibleFor(tempTracersVisible)} />
                <p>All Temps</p>
              </div>
              <div className='row toggle-visibility-container'>
                <CustomCheckBox
                  onChange={() => toggleDataVisibility(100, "relay", allIndicatorsVisibleFor(relayBarsVisible))} type="checkbox"
                  checked={allIndicatorsVisibleFor(relayBarsVisible)} />
                <p>All Relays</p>
              </div>
              <div className='row toggle-visibility-container'>
                <CustomCheckBox
                  onChange={() => toggleDataVisibility(100, "target", allIndicatorsVisibleFor(targetOffsetsVisible))} type="checkbox"
                  checked={allIndicatorsVisibleFor(targetOffsetsVisible)} />
                <p>All Setpoints</p>
              </div>
            </div> :
            <>
              {/* <div className='top-display-select-container'> */}
              {/*   <div className='row toggle-visibility-container' style={{ width: '100%' }}> */}
              {/*     <CustomCheckBox */}
              {/*       onChange={() => toggleDataVisibility(100, "power", allIndicatorsVisibleFor(powerTracersVisible))} type="checkbox" */}
              {/*       checked={allIndicatorsVisibleFor(powerTracersVisible)} /> */}
              {/*     <p>All Duty Cycles</p> */}
              {/*   </div> */}
              {/* </div> */}
            </>
          )}
        {(slideStateId === "slide-up") ?
          <div className='card-scroll-container'>
            {deviceType === 0 ?
              iccOverviewData.expanded?.map((valueObj, index) => {
                return (
                  <LiveDataCard valueObj={valueObj} index={index} selectedRecord={selectedRecord} />
                )
              }) :
              deviceChannelData?.map((channelObj, index) => {
                return (
                  <ExpandedOverviewCard
                    key={index}
                    selectedMenuIndex={selectedMenuIndex}
                    channelObj={channelObj}
                    toggleDataVisibility={toggleDataVisibility}
                    tempTracersVisible={tempTracersVisible}
                    relayBarsVisible={relayBarsVisible}
                    targetOffsetsVisible={targetOffsetsVisible}
                    powerTracersVisible={powerTracersVisible}
                    handleShowEditChannelModal={handleShowEditChannelModal} />
                )
              })
            }
          </div>
          :
          <>
            {(deviceChannelDataLoading ?
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <PuffLoader
                  size={60}
                  color="#042855"
                />
              </div>
              : <>
                {/* THIS IS THE OVERVIEW CARDS */}
                {deviceType === 0
                  ? iccOverviewData.collapsed?.map((valueObj, index) => {
                    return (
                      <PullupCard
                        valueObj={valueObj}
                        index={index}
                        deviceType={deviceType}
                        selectedRecord={selectedRecord}
                      />
                    )
                  })
                  : deviceChannelData?.map((channelObj, index) => {
                    return (
                      <CollapsedOverviewCard
                        key={index}
                        selectedMenuIndex={selectedMenuIndex}
                        channelObj={channelObj}
                      />
                    )
                  })
                }
              </>
            )}
          </>
        }
      </div >
      <div className='overview-display-container' >
        <div className='overview-display-top'>
          <h1 className='overview-header'>
            {headerValue}
          </h1>
          <div style={{ opacity: selectedDisplayTab === 0 ? 1 : 0 }} className='overview-display-top-sub'>
            <div className="date-picker-container" style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
              <DatePicker
                showIcon
                todayButton="Today"
                dateFormat={"dd/MM/yyyy"}
                icon={<KeyboardArrowDownIcon />}
                toggleCalendarOnIconClick
                className="input-field"
                id="date-picker"
                minDate={subDays(new Date(), 30)}
                maxDate={(new Date())}
                autoComplete="off"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="DD/MM/YYYY"
                selected={displayDate}
                onChange={(date) => [setdisplayDate(date), setdateUpdatedFlag(true)]}
              />
              <h4 style={{ margin: '0px 6px', fontWeight: 500 }}>Display Date:</h4>
            </div>
          </div>
        </div>

        {slideStateId === 'slide-up' &&
          (graphLoading
            ? <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <PuffLoader
                size={60}
                color="#042855"
              />
            </div>
            : <GraphContainer
              selectedRecord={selectedRecord}
              displayDate={displayDate}
              tempDisplayData={tempDisplayData}
              relayDisplayData={relayDisplayData}
              powerDisplayData={powerDisplayData}
              powerFactorDisplayData={powerFactorDisplayData}
              graphLoading={graphLoading}
            />
          )
        }

        <div className='overview-display-bottom'>
          {deviceDetails.map((detail, index) => {
            return (
              <div key={index} className='device-detail-row' style={{ opacity: detail.opacity }}>
                <p>{detail.title} </p>
                <p>{detail.value}</p>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
