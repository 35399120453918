import React, { useEffect } from 'react'
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getDeviceStatistics } from '../api/DeviceAPI.js';
import { getRandomColorHex } from '../utils/getRandomColorHex.js'
import { useSelector } from 'react-redux';
import { setFetchedData, setDatasets, statisticsStore, setSelectedUnitId } from '../stores/statisticsStore'
import { statDatasets } from '../constants/statVars.js';

const Dataset = ({ datasetId, deviceId, chartIndex, data, datasetIndex }) => {
  const datasets = useSelector(state => state.datasets)
  const selectedUnitId = useSelector(state => state.selectedUnitId)
  const chart = useSelector(state => state.chart)

  // Populate Dropdown Options
  const populateOptions = () => {
    let allOptions = [...statDatasets.control, ...statDatasets.energy, ...statDatasets.defrost, ...statDatasets.ambient2]
    const selectedObject = allOptions.find(item => item.type === data.type);

    if (selectedObject && datasetIndex === 0 && selectedObject.unitId !== selectedUnitId[chartIndex]) {
      const updatedUnitId = {
        ...selectedUnitId, // Clone the existing object
        [chartIndex]: selectedObject.unitId
      };
      if (updatedUnitId) { statisticsStore.dispatch(setSelectedUnitId(updatedUnitId)); }
    }

    if (datasets[chartIndex].length > 0 && datasetIndex !== 0) {
      return allOptions.filter(option => option.unitId === selectedUnitId[chartIndex]);
    } else {
      return allOptions
    }
  }

  // function to add dataset components
  const removeDataset = (id) => {
    let newDatasets = { ...datasets }
    newDatasets[chartIndex] = datasets[chartIndex].filter((dataset) => dataset.id !== id)
    statisticsStore.dispatch(setDatasets(newDatasets));
  }

  // Handles Datasset Value Change
  const handleDatasetChange = (id, changeValue, value) => {
    let newDatasets = { ...datasets };

    newDatasets[chartIndex] = datasets[chartIndex].map((dataset) =>
      dataset.id === id
        ? dataset[changeValue] !== value // Only update if the value is different
          ? { ...dataset, [changeValue]: value }
          : dataset
        : dataset
    );

    if (JSON.stringify(newDatasets) !== JSON.stringify(datasets)) {
      statisticsStore.dispatch(setDatasets(newDatasets));
    }
  };


  // convert date 
  const convertDateToIncludeOffset = (dateIn, encode) => {
    return dateIn?.toISOString().split('T')[0];
  }

  // Check if dataset Dataset is valid for API Call
  const checkValidDatasets = () => {
    let params;

    datasets[chartIndex].forEach((dataset) => {
      if (dataset.type !== null && chart.startDate && dataset.id === datasetId) {
        params = `?type=${dataset.type}&granularity=${chart.granularity}`;
        params += `&startDate=${convertDateToIncludeOffset(chart.startDate)}`;

        if (chart.granularity === "DAILY") {
          params += `&endDate=${convertDateToIncludeOffset(chart.endDate)}`;
          console.log('yo')
        }

        fetchData(dataset, params);
      }
    });
  };

  function transformData(inputArray) {
    return inputArray.map(item => ({
      _id: crypto.randomUUID(),
      metadata: {
        thingName: item._id.thingName
      },
      type: item._id.type,
      value: item.avg,
      timestamp: new Date(item._id.date).toISOString()
    }));
  }


  // Fetch Graph Data
  const fetchData = async (dataset, params) => {
    try {
      const response = await getDeviceStatistics(deviceId, params);

      if (!response) {
        throw new Error("No data received.");
      }

      let fetchedData = statisticsStore.getState().fetchedData;
      let newFetchedData = { ...fetchedData };
      console.log(response.slice().reverse())
      newFetchedData[dataset.id] = chart.granularity === 'HOURLY' ? response.slice().reverse() : response;
      statisticsStore.dispatch(setFetchedData(newFetchedData));
    } catch (e) {
      alert("Failed to fetch data. Please try again later.");
    }
  };

  // if dataset's type changes then check if dataset is valid for API Call
  useEffect(() => {
    checkValidDatasets();
  }, [data.type, chart.granularity, chart.startDate]);

  return (
    <div className='dataset' key={data.id}>
      <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '5px' }}>
          <FormControl sx={{ width: 150, height: 30 }}>
            <Select
              fullWidth
              value={data.type ?? ""} // Ensures the value is always defined
              input={<OutlinedInput />}
              sx={{ height: 30 }}
              onChange={(e) => handleDatasetChange(data.id, 'type', e.target.value)}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem disabled value="">
                Select an option
              </MenuItem>
              {populateOptions().map((option) => (
                <MenuItem key={option.id} value={option.type}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div
            className='graph-color'
            style={{ backgroundColor: `${data.color}`, borderRadius: '5px' }}
            onClick={() => { handleDatasetChange(data.id, 'color', getRandomColorHex()) }} />
        </div>
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => removeDataset(data.id)} />
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={data.graphType}
            sx={{ flexDirection: 'row' }}
            onChange={(e) => { handleDatasetChange(data.id, 'graphType', e.target.value) }}
          >

            <FormControlLabel value="line" control={<Radio />} label="Line" />
            <FormControlLabel value="bar" control={<Radio />} label="Bar" />
          </RadioGroup>
        </FormControl>
        <div>
          {data.hoverValue}
        </div>
      </div>
    </div>)
}

export default Dataset
