import React, { useEffect, useState } from 'react'
import { dataColors } from '../../../constants/colors';
import { getSingletonPubSub } from '../../../mqtt/MyPubSub';

const LiveDataCard = ({ valueObj, index,  selectedRecord }) => {
  const [cardValues, setCardValues] = useState(valueObj.values)
  const topicBase = `device/${selectedRecord.type}/${selectedRecord.thingName}/${valueObj.type}`

  // MQTT Subscriptions
  useEffect(() => {
    let subscriptions;
    const getLiveValues = async () => {
      try {
        const pubsub = await getSingletonPubSub();

        subscriptions = cardValues.map((value, index) =>
          pubsub.subscribe({
            topics: [`${topicBase}/${value.valueKey}`],
          }).subscribe({
            next: (data) => {
              // console.log(data)
              setCardValues((prevData) => {
                return prevData.map((item, i) =>
                  i === index ? { ...item, value: data.value } : item
                );
              });
            },
            error: (error) => console.error("Subscription error:", error),
            complete: () => console.log("Subscription completed"),
          })
        );
      } catch (error) {
        console.error("Failed to subscribe:", error);
      }
    };

    getLiveValues();

    return () => {
      // Unsubscribe properly when component unmounts
      if (subscriptions) {
        subscriptions.forEach((sub) => sub.unsubscribe());
      }
    };
  }, [])

  return (
    <div key={index} className='data-display-card-expanded' >
      <div className='data-display-start'>
        <h4 style={{ borderRadius: 6, padding: '3px 3px', color: 'white', backgroundColor: 'var(--main-color)' }}>{valueObj.title}</h4>
      </div>
      <div className='data-display-end'>
        {cardValues.map((value, subIndex) => {
          return (
            <div key={subIndex} className='row' style={{ marginTop: '6px' }}>
              {("colorIndex" in value) ?
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ width: '15px', height: '15px', borderRadius: '90px', marginRight: '3px', backgroundColor: dataColors[value.colorIndex] }} />
                  <p>{value.name}</p>
                </div>
                : <p>{value.name}</p>
              }
              <p>{typeof value.value === 'number' ? value.value.toFixed(2) : value.value} {value.unit}</p>
            </div>
          )
        })}
      </div>
    </div>

  )
}

export default LiveDataCard
