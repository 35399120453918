import React, { useEffect, useRef, useState } from "react";
import './styles/StatisticsGraph.css'
import { useSelector } from "react-redux";
import * as d3 from "d3";
import { statisticsStore, setSyncCursor, setSyncZoom, setXScale, setDatasets } from "../../stores/statisticsStore";

const StatisticsGraph = ({ fetchedData, numGraphsDisplayed, sync, chartIndex }) => {
  const svgRef = useRef();
  const chart = useSelector(state => state.chart);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const syncZoom = useSelector(state => state.syncZoom)
  const datasets = useSelector(state => state.datasets)
  const syncCursor = useSelector(state => state.syncCursor)
  const [localZoom, setLocalZoom] = useState(null);
  const [cursor, setCursor] = useState(null);
  const x = useSelector(state => state.xScale)
  const [y, setY] = useState(null);
  const [yAxis, setYAxis] = useState(null)
  const [xAxis, setXAxis] = useState(null);
  const [xGrid, setXGrid] = useState(null);
  const [yGrid, setYGrid] = useState(null);
  const [line, setLine] = useState(null);

  const marginTop = 20;
  const marginRight = 30;
  const marginBottom = 30;
  const marginLeft = 40;
  const width = dimensions.width - marginLeft - marginRight;
  const height = dimensions.height - marginTop - marginBottom;

  // Function to highlight the closest point when cursor hovers
  const closestPoint = (svg, cursorType) => {
    let newX;
    if (sync && syncZoom) {
      newX = syncZoom.transform.rescaleX(x);
    } else if (localZoom) {
      newX = localZoom.transform.rescaleX(x);
    } else {
      newX = x;
    }

    datasets[chartIndex].forEach(d => {
      let data = fetchedData[d.id];

      const bisectTimestamp = d3.bisector(d => new Date(d?.timestamp).getTime()).center;

      const hoveredTimestamp = newX.invert(cursorType.x).getTime();

      // Handle Data and Timestamp Being Null or Undefined 
      if (!data && !hoveredTimestamp) return;
      const index = bisectTimestamp(data, hoveredTimestamp);
      let hoverValue = data[index]?.value;
      let roundedValue = hoverValue?.toFixed(2);
      handleDatasetChange(d.id, 'hoverValue', roundedValue);

      d3.selectAll(`[id^='data-point-${d.id}']`).style('fill', 'white').attr('r', '2.5');

      const dataPoint = svg.select(`#data-point-${d.id}-${index}`);
      dataPoint.style('fill', 'white').attr('r', '5');
    });
  };

  const handleDatasetChange = (id, changeValue, value) => {
    let newDatasets = { ...datasets };

    newDatasets[chartIndex] = datasets[chartIndex].map((dataset) =>
      dataset.id === id
        ? dataset[changeValue] !== value // Only update if the value is different
          ? { ...dataset, [changeValue]: value }
          : dataset
        : dataset
    );

    if (JSON.stringify(newDatasets) !== JSON.stringify(datasets)) {
      statisticsStore.dispatch(setDatasets(newDatasets));
    }
  };

  // Handle Responsiveness of Graph
  useEffect(() => {
    const handleResize = () => {
      if (svgRef.current) {
        const { width, height } = svgRef.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    };

    handleResize();

    const resizeObserver = new ResizeObserver(handleResize);
    if (svgRef.current) {
      resizeObserver.observe(svgRef.current.parentElement);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (svgRef.current) {
        resizeObserver.unobserve(svgRef.current.parentElement);
      }
    };

  }, [numGraphsDisplayed]);

  // Generate Graph
  useEffect(() => {
    if (!datasets[chartIndex] || datasets[chartIndex].length === 0 || dimensions.width === 0 || dimensions.height === 0) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous content

    const nX = d3.scaleTime().range([marginLeft, width + marginRight]);
    statisticsStore.dispatch(setXScale(nX));
    const nY = d3.scaleLinear().range([height - marginBottom, marginTop]);
    setY(() => nY);

    // Filter and validate data
    const allData = datasets[chartIndex]
      .flatMap(dataset => (fetchedData[dataset.id] || []).filter(d => d.timestamp));

    if (allData.length === 0) {
      console.warn("No valid data points available for rendering.");
      return;
    }

    // Calculate combined domain for x and y scales
    const maxDate = d3.max(allData, d => new Date(d.timestamp));
    const minDate = d3.min(allData, d => new Date(d.timestamp));
    const yDomain = d3.extent(allData, d => d.value !== null ? d.value : undefined);

    if (!minDate || !maxDate || !yDomain) {
      console.error("Invalid domain values:", { minDate, maxDate, yDomain });
      return;
    }

    const startOfDay = d3.timeDay.floor(minDate);
    const endOfDay = d3.timeDay.ceil(maxDate);
    nX.domain([startOfDay, endOfDay]);

    // Add padding to y domain
    const yPadding = (yDomain[1] - yDomain[0]) * 0.1; // 10% padding
    nY.domain([yDomain[0] - yPadding, yDomain[1] + yPadding]);

    const nLine = d3.line()
      .defined(d => d.value !== null) // Include only points with non-null values
      .x(d => {
        let newX;
        if (sync && syncZoom) {
          newX = syncZoom.transform.rescaleX(nX);
        } else if (localZoom) {
          newX = localZoom.transform.rescaleX(nX);
        } else {
          newX = nX;
        }

        const date = new Date(d.timestamp);
        return newX(chart.granularity === 'DAILY' ? d3.timeDay.floor(date) : date);
      })
      .y(d => nY(d.value));

    setLine(() => nLine);

    const xBand = d3.scaleBand().range([marginLeft, width - marginRight]);

    // Set x-axis with exactly 10 ticks
    const nXAxis = chart.granularity === 'HOURLY'
      ? d3.axisBottom(nX).ticks(10).tickSizeOuter(0)
      : d3.axisBottom(nX).ticks(10).tickSizeOuter(0).tickFormat(d3.timeFormat("%d %b"));
    setXAxis(() => nXAxis);

    // Set y-axis with exactly 10 ticks
    const nYAxis = d3.axisLeft(nY).ticks(10);
    setYAxis(() => nYAxis);

    const nXGrid = d3.axisBottom(nX).ticks(10).tickSize(marginTop + marginBottom - height).tickFormat('');
    setXGrid(() => nXGrid);

    const nYGrid = d3.axisLeft(nY).ticks(10).tickSize(10 - width).tickFormat('');
    setYGrid(() => nYGrid);

    xBand.domain(allData.map(d => new Date(d.timestamp)));

    if (chart.granularity === 'DAILY') {
      nX.domain([d3.timeDay.floor(nX.domain()[0]), d3.timeDay.ceil(nX.domain()[1])]);
    }

  }, [fetchedData, chart.granularity, dimensions, numGraphsDisplayed, sync]);

  useEffect(() => {
    if (line === null || xGrid === null || yGrid === null || xAxis === null || yAxis === null || datasets[chartIndex] === null) return

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous content

    const clipX = marginLeft + 1;
    const clipY = marginTop;
    const clipWidth = width - marginLeft + marginRight;
    const clipHeight = height - marginTop - marginBottom;

    // Create a clip path to restrict the lines to the graph area
    const clipPath = svg.append("defs").append("clipPath").attr("id", "clip-graph");
    clipPath.append("rect")
      .attr("x", clipX)
      .attr("y", clipY)
      .attr("fill", "#808080")
      .attr("width", clipWidth)
      .attr("height", clipHeight);

    // svg.append("rect")
    //   .attr("x", clipX)
    //   .attr("y", clipY)
    //   .attr("fill", "#808080")
    //   .attr("clip-path", "url(#clip-graph)")
    //   .attr("width", clipWidth)
    //   .attr("height", clipHeight); 

    const zoom = d3.zoom()
      .scaleExtent([1, 10])
      .translateExtent([[0, 0], [width, height]])
      .extent([[0, 0], [width, height]])
      .on("zoom", (event) => {
        if (sync) {
          statisticsStore.dispatch(setSyncZoom(event));
        } else {
          setLocalZoom(event);
        }

        // Update axes during zoom
        svg.selectAll(".x-axis").call(xAxis.scale(event.transform.rescaleX(x)).ticks(10));
        svg.selectAll(".x-grid").call(xGrid.scale(event.transform.rescaleX(x)).ticks(10));
      });

    svg.call(zoom);

    svg.append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .attr("class", "x-axis")
      .call(xAxis)
      .attr("width", width); // Ensure x-axis spans full width

    svg.append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .attr("class", "y-axis")
      .call(yAxis);

    svg.append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .attr("class", "x-grid")
      .call(xGrid)
      .attr("stroke-opacity", 0.1);

    svg.append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .attr("class", "y-grid")
      .call(yGrid)
      .attr("stroke-opacity", 0.1);

    datasets[chartIndex].forEach(dataset => {
      const data = fetchedData[dataset.id];
      if (!data) return;

      const tickWidth = (width - marginLeft - marginRight) / data.length;
      const barWidth = tickWidth * 0.5;

      if (dataset.graphType === "line") {
        svg.append("path")
          .datum(data)
          .attr("fill", "none")
          .attr("stroke", dataset.color)
          .attr("stroke-width", 1.5)
          .attr("class", "line")
          .attr("clip-path", "url(#clip-graph)")
          .attr("d", line);

        svg.append("g")
          .selectAll("circle")
          .data(data)
          .enter()
          .append("circle")
          .attr("id", (d, i) => `data-point-${dataset.id}-${i}`)
          .attr("fill", "white")
          .attr("stroke", dataset.color)
          .attr("stroke-width", 1.5)
          .attr("clip-path", "url(#clip-graph)")
          .attr("cx", d => {
            let newX;
            if (sync && syncZoom) {
              newX = syncZoom.transform.rescaleX(x)
            } else if (localZoom) {
              newX = localZoom.transform.rescaleX(x)
            } else {
              newX = x;
            }

            const date = new Date(d.timestamp);
            return newX(chart.granularity === 'DAILY' ? d3.timeDay.floor(date) : date);
          })
          .attr("cy", d => y(d.value))
          .attr("r", 2.5);

      } else if (dataset.graphType === "bar") {

        svg.append("g")
          .selectAll(".bar")
          .data(data)
          .enter()
          .append("rect")
          .attr("class", "bar")
          .attr("x", d => {
            let newX;
            if (sync && syncZoom) {
              newX = syncZoom.transform.rescaleX(x)
            } else if (localZoom) {
              newX = localZoom.transform.rescaleX(x)
            } else {
              newX = x;
            }
            return newX(chart.granularity === 'DAILY' ? d3.timeDay.floor(new Date(d.timestamp)) : new Date(d.timestamp)) - barWidth / 2
          })
          .attr("width", barWidth)
          .attr("clip-path", "url(#clip-graph)")
          .attr("y", d => y(d.value))
          .attr("height", d => height - marginBottom - y(d.value))
          .attr("fill", dataset.color);
      }
    });

    // Add overlay for capturing mouse events
    svg.append("rect")
      .attr("class", "overlay")
      .attr("width", width)
      .attr("height", height)
      .attr("fill", "none")
      .attr('clip-path', 'url(#clip-graph)')
      .attr("pointer-events", "all")
      .on("mousemove", function (event) {
        const [mouseX] = d3.pointer(event);
        const hoveredDate = x.invert(mouseX);

        if (sync) {
          statisticsStore.dispatch(setSyncCursor({ x: mouseX, y: hoveredDate }));
        } else {
          setCursor({ x: mouseX, y: hoveredDate });
        }
      });

  }, [dimensions, line, xGrid, yGrid, xAxis, yAxis, datasets[chartIndex]])

  // CURSOR
  useEffect(() => {
    let newX;
    if (sync && syncZoom) {
      newX = syncZoom.transform.rescaleX(x)
    } else if (localZoom) {
      newX = localZoom.transform.rescaleX(x)
    } else {
      newX = x;
    }

    const svg = d3.select(svgRef.current);

    svg.select("#cursor-line").remove();
    svg.select(".time-box").remove();
    svg.select(".time-box-bg").remove();

    // Create a background rectangle for the time box
    let timeBoxBg = svg.append("rect")
      .attr("class", "time-box-bg")
      .attr("width", 100)
      .attr("height", 25)
      .attr("fill", "white")
      .attr("stroke", "black")
      .attr("stroke-width", 1)
      .attr("rx", 2)
      .attr("ry", 2)
      .style("display", "none");

    let timeBox = svg.append("text")
      .attr("class", "time-box")
      .attr("text-anchor", "middle")
      .attr("y", height - marginBottom + 20)
      .style("fill", "black") // Text color
      .style("display", "none");

    let cursorLine = svg.append("line")
      .attr("id", "cursor-line")
      .attr("stroke", "black")
      .attr("stroke-width", 1)
      .attr("y1", marginTop)
      .attr("y2", height - marginBottom);

    const formatTime = d3.timeFormat(chart.granularity === 'HOURLY' ? "%H:%M" : "%d/%m %H:%M");

    if (sync && syncCursor) {
      closestPoint(svg, syncCursor);

      const rescaledDate = newX.invert(syncCursor.x);

      cursorLine
        .attr("x1", syncCursor.x)
        .attr("x2", syncCursor.x)
        .style("display", "block");

      timeBox
        .attr("x", syncCursor.x)
        .text(formatTime(rescaledDate))
        .style("display", null);

      timeBoxBg
        .attr("x", syncCursor.x - 50)
        .attr("y", height - marginBottom + 1)
        .style("display", null);

    } else if (cursor) {
      closestPoint(svg, cursor);

      const rescaledDate = newX.invert(cursor.x);

      cursorLine
        .attr("x1", cursor.x)
        .attr("x2", cursor.x)
        .style("display", "block");

      timeBox
        .attr("x", cursor.x)
        .text(formatTime(rescaledDate))
        .style("display", null);

      timeBoxBg
        .attr("x", cursor.x - 50)
        .attr("y", height - marginBottom + 1)
        .style("display", null);
    }
  }, [syncCursor, cursor]);


  // ZOOM 
  useEffect(() => {
    const svg = d3.select(svgRef.current);
    // Get current zoom transform
    const currentTransform = d3.zoomTransform(svg.node());

    if (sync && syncZoom && x && y) {
      // Only apply the transform if it has changed
      if (
        currentTransform.x !== syncZoom.transform.x ||
        currentTransform.y !== syncZoom.transform.y ||
        currentTransform.k !== syncZoom.transform.k
      ) {
        svg.call(d3.zoom().transform, syncZoom.transform);
      }

      datasets[chartIndex].forEach(dataset => {
        const data = fetchedData[dataset.id];
        const newX = syncZoom.transform.rescaleX(x);
        // statisticsStore.dispatch(setXScale(newX));
        const tickWidth = (width - marginLeft - marginRight) / data.length;
        const barWidth = tickWidth * 0.7;

        svg.selectAll(".x-axis").call(xAxis.scale(newX));
        svg.selectAll(".x-grid").call(xGrid.scale(newX));

        svg.selectAll(".line")
          .attr("d", line
            .x(d => {
              const date = new Date(d.timestamp);
              return newX(chart.granularity === 'DAILY' ? d3.timeDay.floor(date) : date);
            })
            .y(d => y(d.value)));

        svg.selectAll("circle")
          .attr("cx", d => {
            const date = new Date(d.timestamp);
            return newX(chart.granularity === 'DAILY' ? d3.timeDay.floor(date) : date);
          })
          .attr("cy", d => y(d.value));

        svg.selectAll(".bar")
          .attr("x", d => newX(chart.granularity === 'DAILY'
            ? d3.timeDay.floor(new Date(d.timestamp))
            : new Date(d.timestamp)) - barWidth / 2)
          .attr("width", barWidth)
          .attr("y", d => y(d.value))
          .attr("height", d => height - marginBottom - y(d.value));
      });
    } else if (localZoom) {
      // Get current zoom transform
      const currentTransform = d3.zoomTransform(svg.node());

      // Only apply the transform if it has changed
      if (
        localZoom.transform && currentTransform.x !== localZoom.transform.x ||
        currentTransform.y !== localZoom.transform.y ||
        currentTransform.k !== localZoom.transform.k
      ) {
        svg.call(d3.zoom().transform, localZoom.transform);
      }

      datasets[chartIndex].forEach(dataset => {
        const data = fetchedData[dataset.id];
        const newX = localZoom.transform.rescaleX(x);
        // statisticsStore.dispatch(setXScale(newX));
        const tickWidth = (width - marginLeft - marginRight) / data.length;
        const barWidth = tickWidth * 0.7;

        svg.selectAll(".x-axis").call(xAxis.scale(newX));
        svg.selectAll(".x-grid").call(xGrid.scale(newX));

        svg.selectAll(".line")
          .attr("d", line
            .x(d => {
              const date = new Date(d.timestamp);
              return newX(chart.granularity === 'DAILY' ? d3.timeDay.floor(date) : date);
            })
            .y(d => y(d.value)));

        svg.selectAll("circle")
          .attr("cx", d => {
            const date = new Date(d.timestamp);
            return newX(chart.granularity === 'DAILY' ? d3.timeDay.floor(date) : date);
          })
          .attr("cy", d => y(d.value));

        svg.selectAll(".bar")
          .attr("x", d => newX(chart.granularity === 'DAILY'
            ? d3.timeDay.floor(new Date(d.timestamp))
            : new Date(d.timestamp)) - barWidth / 2)
          .attr("width", barWidth)
          .attr("y", d => y(d.value))
          .attr("height", d => height - marginBottom - y(d.value));
      });
    }
  }, [syncZoom, localZoom]);

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <svg className="statistics-graph" ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>
    </div>
  );
};

export default StatisticsGraph;
