export const getRandomColorHex = () => {
  let rgb = [0, 0, 0];
  const maxIndex = Math.floor(Math.random() * 3); // Choose which channel gets 255
  rgb[maxIndex] = 255;

  for (let i = 0; i < 3; i++) {
    if (i !== maxIndex) {
      rgb[i] = Math.floor(Math.random() * 176) + 80; // Range 80-255
    }
  }

  // Convert to hex format
  return `#${rgb.map(c => c.toString(16).padStart(2, '0')).join('')}`;
}
